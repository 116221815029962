import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chatid: {},
  chatHistory: [],
  isBlocked: false,
  chatHistoryPageNo: 1,
  isChatHistoryReset: false,
  isnewMessage: 0,
  chatAllowed: false,
  adminName: "",
  userId:"",
  ticketId: "",
  ticketStatus: "",
};

export const chatSlice = createSlice({
  name: "chatid",
  initialState,
  reducers: {
    selectedChatid: (state, action) => {
      state.chatid = action.payload;
    },
    selectedChatHistory: (state, action) => {
      state.chatHistory = action.payload;
    },
    selctedUserIsBlocked: (state, action) => {
      state.isBlocked = action.payload;
    },
    selectedChatAllowed: (state = initialState, action) => {
      state.chatAllowed = action.payload;
    },
    selectedTicketStatus: (state = initialState, action) => {
      state.ticketStatus = action.payload;
    },
    selectedChatHistoryPageNo: (state, action) => {
      state.chatHistoryPageNo = action.payload;
    },
    selectedisChatHistoryReset: (state, action) => {
      state.isChatHistoryReset = action.payload;
    },
    selectedisnewMessage: (state, action) => {
      state.isnewMessage = action.payload;
    },
    selectedAdminName: (state, action) => {
      state.adminName = action.payload;
    },
    selectedUserId: (state, action) => {
      state.userId = action.payload;
    },
    selectedTicketId: (state, action) => {
      state.ticketId = action.payload;
    },
  },
});
export default chatSlice.reducer;
export const {
  selectedChatid,
  selectedChatHistory,
  selctedUserIsBlocked,
  selectedChatHistoryPageNo,
  selectedisChatHistoryReset,
  selectedisnewMessage,
  selectedChatAllowed,
  selectedAdminName,
  selectedUserId,
  selectedTicketId,
  selectedTicketStatus
} = chatSlice.actions;
