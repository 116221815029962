import React, { useState, useEffect } from "react";
// Import of material components
import {
  Tooltip,
  Typography,
  MenuItem,
  Select,
  Paper,
  Drawer,
  Box,
  Button,
  IconButton,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
// import of this application components
import { AppContent, AppHeader } from "../components/navbar";
import GridComponent from "../components/common/Grid";
import CustomButton from "../components/common/CustomButton";
//import of dependency
import axiosInstance from "../api/interceptors/axiosInstance";
import { Link } from "react-router-dom"; // to link page
//search starts
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
import DateFormatter from "../components/common/DateFromatter";
import { CUSTOMERS_FEEDBACK } from "../utils/constants/api_constant";
import CleanIcon from "../assets/Clean Icon.svg";
import StandardDate from "../components/common/StandardDate";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Hidden from "@mui/material/Hidden";
import AlertModal from "../components/common/AlertModal";
const CustomerFeedback = () => {
  const today = dayjs();
  const [searchByUser, setSearchByUser] = useState("");
  const [gridKey, setGridKey] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isSearchDisabled, setIsSearchDisabled] = useState(true);
  const commentOptions = ["Bad", "Very_Bad", "Okay", "Good", "Great"];
  const displayComment = (comment) => comment.replace("_", " ");
  const [commentType, setCommentType] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // To set date format

  const handleStartDate = (date) => {
    const sd = StandardDate(date);
    setStartDate(sd);
    updateSearchButtonState(sd, endDate, searchByUser, commentType);
  };
  const handleEndDate = (date) => {
    const ed = StandardDate(date);
    setEndDate(ed);
    updateSearchButtonState(startDate, ed, searchByUser, commentType);
  };
  // Alert message function
  const handleDialogOpen = (title, content) => {
    setDialogTitle(title);
    setDialogContent(content);
    setOpen(true);
  };
  const handleDialogClose = () => {
    setOpen(false);
  };
  const handleFilterClick = () => {
    setIsDrawerOpen(true);
  };
  const handleFilterClose = () => {
    setIsDrawerOpen(false);
  };

  //API call for Customer FeedBack
  const fetchData = (pageState, setPageState) => {
    let params = `pageNo=${pageState.page}&pageSize=${pageState.pageSize}`;
    if (searchByUser !== "") {
      const searchParams = `&searchByUser=${searchByUser}`;
      params += `${searchParams}`;
    }
    if (commentType !== "") {
      const commentParams = `&commentType=${commentType}`;
      params += `${commentParams}`;
    }
    if (startDate !== "" && endDate !== "") {
      const dateParams = `&startDate=${startDate}&endDate=${endDate}`;
      params += `${dateParams}`;
    }

    setPageState((old) => ({ ...old, isLoading: true }));

    const url = `${CUSTOMERS_FEEDBACK}?${params}`;

    return new Promise((resolve, reject) => {
      axiosInstance
        .get(url)
        .then((response) => {
          resolve(
            setMessage(response.data.message),
            setError(""),

            setPageState((old) => ({
              ...old,
              isLoading: false,
              data: response.data.userFeedbackDTOList,
              total: response.data.totalElements,
            }))
          );
        })
        .catch((error) => {
          console.error(error);
          setMessage("");
          setError(error.errorMessage || error?.response?.data?.message);
        });
    });
  };
  //Column data for Customer Feedback
  const customerFeedback = [
    {
      headerName: (
        <Typography variant="h10" fontWeight="bold">
          Name
        </Typography>
      ),
      field: "userName",
      renderCell: (rowData) => {
        const fullName = rowData?.row?.userName;

        return (
          <span>
            {fullName ? (
              <Link
                to={`/customer-details/${rowData?.row?.userId}`}
                style={{ textDecoration: "none" }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {fullName}
                </div>
              </Link>
            ) : (
              <span style={{ color: "red" }}>Not Registered</span>
            )}
          </span>
        );
      },
      width: 180,
    },

    {
      headerName: (
        <Typography variant="h10" fontWeight="bold">
          Dismiss prompt
        </Typography>
      ),
      field: "doNotAskAgain",
      width: 100,
      renderCell: (params) => (
        <div style={{ color: params.value ? "green" : "red" }}>
          {params.value ? "Enabled" : "Disabled"}
        </div>
      ),
    },
    {
      headerName: (
        <Typography variant="h10" fontWeight="bold">
          Campaign Number
        </Typography>
      ),
      field: "campaignNumber",
      width: 150,
    },
    {
      headerName: (
        <Typography variant="h10" fontWeight="bold">
          Comment Type
        </Typography>
      ),
      field: "commentType",
      width: 140,
      renderCell: (rowData) => {
        const commentType = rowData?.row?.commentType;
        const formattedCommentType = commentType
          ? commentType.replace(/_/g, " ")
          : "N/A";

        return (
          <span
            style={{
              color: formattedCommentType === "N/A" ? "red" : "inherit",
            }}
          >
            {formattedCommentType}
          </span>
        );
      },
    },
    {
      headerName: (
        <Typography variant="h10" fontWeight="bold">
          Comment Description
        </Typography>
      ),
      field: "commentDescription",
      width: 250,
      renderCell: (rowData) => {
        const comment = rowData?.row?.commentDescription;

        return (
          <Tooltip title={comment ? comment : "N/A"}>
            <span
              style={{
                display: "block",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "230px",
              }}
            >
              {comment ? comment : <span style={{ color: "red" }}>N/A</span>}
            </span>
          </Tooltip>
        );
      },
    },
    {
      headerName: (
        <Typography variant="h10" fontWeight="bold">
          Account Created on (MM-DD-YYYY HH:MM)
        </Typography>
      ),
      field: "createdAt",
      renderCell: (rowData) => {
        const created_date = rowData?.row?.createdAt;
        return <DateFormatter dateString={created_date} />;
      },
      width: 200,
    },
  ];

  useEffect(() => {
    if (message || error) {
      const timer = setTimeout(() => {
        setMessage("");
        setError("");
      }, 4000);

      return () => {
        clearTimeout(timer);
        setMessage("");
        setError("");
      };
    }
  }, [message, error]);

  const handleSearch = () => {
    if (searchByUser || commentType) {
      setGridKey((prevKey) => prevKey + 1);
    } else {
      if (startDate !== "" && endDate !== "") {
        var [day, month, year] = startDate.split("-");
        const stDate = new Date(year, parseInt(month) - 1, parseInt(day));
        [day, month, year] = endDate.split("-");
        const enDate = new Date(year, parseInt(month) - 1, parseInt(day));

        if (stDate > enDate) {
          handleDialogOpen(
            "Date Error",
            "End date must be greater than Start date."
          );
        } else {
          setGridKey((prevKey) => prevKey + 1);
        }
      } else if (
        (startDate === "" && endDate !== "") ||
        (startDate !== "" && endDate === "")
      ) {
        handleDialogOpen(
          "Date Error",
          "Please select both start and end dates."
        );
      } else {
        handleDialogOpen("Search Error", "Please provide search criteria.");
      }
    }
    handleFilterClose();
  };

  const updateSearchButtonState = (
    startDate,
    endDate,
    searchByUser,
    commentType
  ) => {
    setIsSearchDisabled(!(startDate || endDate || searchByUser || commentType));
  };

  const handleSearchOnEnter = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const clearFilters = () => {
    setSearchByUser("");
    setStartDate("");
    setEndDate("");
    setCommentType("");
    setGridKey((prevKey) => prevKey + 1);
  };
  return (
    <div>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <AppContent />

          <Grid container item lg={12} md={12} sm={12} spacing={2} >
            <Grid item xs={7} sm={6} md={4} lg={6} >
              <h5>Customers Feedback</h5>
            </Grid>
            <Hidden mdUp smUp>
              <Grid item xs={5} sm={6} md={6} lg={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {searchByUser || startDate || endDate || commentType ? (
                    <IconButton
                      onClick={clearFilters}
                      title="Clear all filters"
                    >
                      <img
                        src={CleanIcon}
                        alt="Clear"
                        style={{
                          width: "19px",
                          height: "24px",
                          color: "#007BFF",
                        }}
                      />
                    </IconButton>
                  ) : null}
                  <Button
                    onClick={handleFilterClick}
                    startIcon={<FilterListIcon />}
                  >
                    Filters
                  </Button>
                </div>
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={6} md={7} lg={6}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Grid item xs={12} sm={6} md={7} lg={6}>
                  <TextField
                    type="text"
                    placeholder="Search..."
                    value={searchByUser}
                    onChange={(e) => setSearchByUser(e.target.value)}
                    onKeyPress={handleSearchOnEnter}
                    InputProps={{
                      style: {
                        height: "46px",
                        width: "100%",
                      },
                    }}
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "56px", // Add border radius here
                        "&:hover fieldset": {
                          borderColor: "#3f1e81",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#3f1e81",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={5}
                  sm={3}
                  md={2.3}
                  lg={2.3}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <CustomButton
                    onClick={handleSearch}
                    height="40px"
                    width="55px"
                    margin="5px"
                    startIcon={<SearchIcon />}
                  />
                  {!isSmallScreen &&
                    (searchByUser || startDate || endDate || commentType) && (
                      <IconButton
                        onClick={clearFilters}
                        title="Clear all filters"
                      >
                        <img
                          src={CleanIcon}
                          alt="Clear"
                          style={{
                            width: "19px",
                            height: "24px",
                            color: "#007BFF",
                          }}
                        />
                      </IconButton>
                    )}
                </Grid>

                {!isSmallScreen && (
                  <Grid
                    item
                    xs={2.5}
                    sm={2.5}
                    md={2.5}
                    lg={2.5}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      onClick={handleFilterClick}
                      startIcon={<FilterListIcon />}
                    >
                      Filters
                    </Button>
                  </Grid>
                )}
              </div>
            </Grid>
          </Grid>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "1rem",
              marginTop:"0.3rem"
            }}
          >
            <div>
              <span style={{ color: "green", fontWeight: "bold" }}>
                {message ? message : ""}
              </span>
              <span style={{ color: "red", fontWeight: "bold" }}>
                {error ? error : ""}
              </span>
            </div>

            <GridComponent
              key={gridKey}
              columns={customerFeedback}
              fetchData={fetchData}
              gridName="Customers"
            />
          </Paper>
        </div>
      </div>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleFilterClose}
        PaperProps={{
          sx: {
            width: 350,
            height: "auto",
            maxHeight: "80%",
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
            padding: 3,
            overflow: "auto",
            top: "60px",
          },
        }}
      >
        <Box>
          <Typography id="filter-modal-title" variant="h6" component="h2">
            Filters
          </Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel sx={{ margin: "5px" }}>Comment Type</InputLabel>
                <Select
                  value={commentType}
                  onChange={(event) => setCommentType(event.target.value)}
                >
                  {commentOptions.map((comment) => (
                    <MenuItem key={commentType} value={comment}>
                      {displayComment(comment)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={<div style={{ margin: "5px" }}>Start Date</div>}
                  onChange={handleStartDate}
                  value={
                    startDate !== "" ? dayjs(startDate, "DD-MM-YYYY") : null
                  }
                  format="DD-MM-YYYY"
                  maxDate={today}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={<div style={{ margin: "5px" }}>End Date</div>}
                  onChange={handleEndDate}
                  value={endDate !== "" ? dayjs(endDate, "DD-MM-YYYY") : null}
                  format="DD-MM-YYYY"
                  maxDate={today}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
            {startDate || endDate || commentType ? (
              <IconButton onClick={clearFilters} title="Clear all filters">
                <img
                  src={CleanIcon}
                  alt="Clear"
                  style={{ width: "19px", height: "24px", color: "#007BFF" }}
                />
              </IconButton>
            ) : null}
            <Button onClick={handleFilterClose} variant="contained" style={{ background: "red", color: "white" , marginRight: "10px", }}>
              Close
            </Button>

            <Button onClick={handleSearch} variant="contained" sx={{ ml: 2 }} style={{ background: "green", color: "white" , marginRight: "10px", }}>
              Apply
            </Button>
          </Box>
        </Box>
      </Drawer>
      <AlertModal
        open={open}
        title={dialogTitle}
        content={dialogContent}
        onClose={handleDialogClose}
      />
    </div>
  );
};

export default CustomerFeedback;
