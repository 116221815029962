import axiosInstance from "./interceptors/axiosInstance";
import {
  LOGIN_ENDPOINT,
  OTP_VERIFY_ENDPOINT,
  LOGOUT_ENDPOINT,
} from "../utils/constants/api_constant";
import { useDispatch, useSelector } from "react-redux";
import { selectedIsPasswordSet } from "../store/slices/PasswordSlice";
 
 
 
// export async function loginapi(params) {
//   try {
//     const {
//       postData,
//       setLoginError,
//       setloginMsg,
//       setNavigate,
//       snackKey,
//       setSnackKey,
//     } = params;
//     const headers = {
//       "Content-Type": "application/json ; charset=UTF-8", // Content type header, adjust as needed
//     };
 
//     axiosInstance
//       .post(LOGIN_ENDPOINT, postData, {
//         headers: headers,
//       })
//       .then((response) => {
//         if (response) {
//           setLoginError("");
//           setloginMsg(response.data.message);
//           setNavigate(true);
//           const temptoken = response.headers["x-auth-temporary-token"];
//           localStorage.setItem("tdocket", temptoken);
//           return temptoken;
//         } else {
//           setLoginError("Invalid Inputs");
//           setNavigate(false);
//         }
//         setSnackKey((prevKey) => prevKey + 1);
//       })
//       .catch((error) => {
//         // Handle errors
//         setloginMsg("");
//         setNavigate(false);
//         setLoginError(error.response.data.message);
//         setSnackKey((prevKey) => prevKey + 1);
//       });
//   } catch (error) {
//     console.error("Error fetching customers:", error);
//     return [];
//   }
// }
 
 
export async function loginapi(params) {
  try {
    const {
      postData,
      setLoginError,
      setloginMsg,
      setNavigate,
      snackKey,
      setSnackKey,
    } = params;
 
    const headers = {
      "Content-Type": "application/json ; charset=UTF-8", 
    };
 
    axiosInstance
      .post(LOGIN_ENDPOINT, postData, { headers })
      .then((response) => {
        if (response) {
          setLoginError("");
          setloginMsg(response.data.message);
          setNavigate(true);
 
          // Fetch x-auth-access-token every time
          const accessToken = response.headers["x-auth-access-token"];
          if (accessToken) {
            localStorage.setItem("tdocket", accessToken); // Store token in localStorage
            return accessToken; 
          } else {
            setLoginError("Token not found in response headers.");
            setNavigate(false);
          }
        } else {
          setLoginError("Invalid Inputs");
          setNavigate(false);
        }
        setSnackKey((prevKey) => prevKey + 1);
      })
      .catch((error) => {
        // Handle errors
        setloginMsg("");
        setNavigate(false);
        setLoginError(error.response?.data?.message || "An error occurred");
        setSnackKey((prevKey) => prevKey + 1);
      });
  } catch (error) {
    console.error("Error fetching login data:", error);
    return [];
  }
}
 
export async function otpverifyapi(params) {
  try {
   
    const {
      postData,
      setOtpError,
      setOtpMsg,
      setNavigate,
      setSnackKey,
      setIsPasswordSet,
    } = params;
    const tempaccessToken = localStorage.getItem("tdocket");
    const headers = {
      "Content-Type": "application/json ; charset=UTF-8", 
      Authorization: `Bearer ${tempaccessToken}`,
    };
   
    axiosInstance
      .post(OTP_VERIFY_ENDPOINT, postData, {
        headers: headers,
      })
      .then((response) => {
        if (response) {
          setOtpError("");
          setOtpMsg(response.data.message);
          setIsPasswordSet(response.data.isPasswordSet);
          setNavigate(true);
         
          const accesstoken = response.headers["x-auth-access-token"];
          const refreshtoken = response.headers["x-auth-refresh-token"];
          localStorage.setItem("adocket", accesstoken);
          // localStorage.setItem("rdocket", refreshtoken);
          //added to check for passwordset 19may24
          localStorage.setItem("isPasswordSet", response.data.isPasswordSet);
       
         
        } else {
          setOtpError("Invalid Inputs");
          setNavigate(false);
        }
        setSnackKey((prevKey) => prevKey + 1);
      })
      .catch((error) => {
        // Handle errors
        setOtpMsg("");
        setNavigate(false);
        //  setOtpError(error.response.data.message);
        if (error.response) {
          // const { message, errorCode, timestamp } = error.response.data; // Destructure response data
          // const errorMessage = `${message} (Code: ${errorCode}, Timestamp: ${timestamp})`;
          // setOtpError(errorMessage); 
          const { message } = error.response.data; 
          setOtpError(message);
    
        } else {
          setOtpError("Network error. Please try again."); 
        }
        // setOtpError(error.errorMessage || "An error occurred.");
         setSnackKey((prevKey) => prevKey + 1);
      });
     
  } catch (error) {
    console.error("Error fetching customers:", error);
 
    return "";
  }
}
 
export async function logoutapi() {
  try {
    const accessToken = localStorage.getItem("adocket");
 
    const headers = {
      "Content-Type": "application/json ; charset=UTF-8",
      Authorization: `Bearer ${accessToken}`,
    };
 
    axiosInstance
      .get(LOGOUT_ENDPOINT, {
        headers: headers,
      })
      .then((response) => {
        return response.data.message;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error fetching customers:", error);
 
    return "";
  }
}
