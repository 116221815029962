import {configUrl} from "./config";

const env = process.env.REACT_APP_DOMAIN || "staging";

let configDefault;

switch (env) {
  case "development":
    configDefault = configUrl.dev_config;
    break;
  case "local":
    configDefault = configUrl.local_config;
    break;
  case "staging":
    configDefault = configUrl.staging_config;
    break;
  case "qa":
    configDefault = configUrl.qa_config;
    break;
  case "prod":
    configDefault = configUrl.prod_config;
    break;
  default:
    throw new Error(`Invalid environment: ${env}`);
}

export default configDefault;
