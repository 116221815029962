import React, { useState, useEffect } from "react";
import { AppContent, AppHeader } from "../components/navbar";
import GridComponent from "../components/common/Grid";
import axiosInstance from "../api/interceptors/axiosInstance";
import CustomButton from "../components/common/CustomButton";
import dayjs from "dayjs";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DateFormatter from "../components/common/DateFromatter";
import {
  Tooltip,
  Typography,
  MenuItem,
  Select,
  Paper,
  Drawer,
  Button,
  Box,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
import CleanIcon from "../assets/Clean Icon.svg";
import AddAlertNotice from "../components/common/AlertNotice/AddAlertNotice";
import UpdateAlertNotice from "../components/common/AlertNotice/UpdateAlertNotice";
import ExpandableImage from "../components/common/ExpandableImage";
import StandardDate from "../components/common/StandardDate";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Hidden from "@mui/material/Hidden";
import AlertModal from "../components/common/AlertModal";

const AlertNotice = ({ accessType }) => {
  const [message, setMessage] = useState("");
  const [appNoticeMessage, setAppNoticeMessage] = useState("");
  const [error, setError] = useState("");
  const [gridKey, setGridKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alert, setAlert] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [osName, setOsName] = useState("");
  const today = dayjs();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isSearchDisabled, setIsSearchDisabled] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isActive, setIsActive] = useState("");
  const [titleError, setTitleError] = useState("");
  const [appNoticeMessageError, setAppNoticeMessageError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const osType = ["ALL", "ANDROID", "IOS"];

  // Open add alert Modal
  const addHandleClose = () => {
    setIsModalOpen(true);
  };
  //Close edit  alert Modal
  const handleClose = () => {
    setIsEditModalOpen(false);
  };
  //Open edit  alert Modal
  const handleEditIconClick = (rowData) => {
    setSelectedRowData(rowData);
    setIsEditModalOpen(true);
  };
  const handleDialogOpen = (title, content) => {
    setDialogTitle(title);
    setDialogContent(content);
    setOpen(true);
  };
  const handleDialogClose = () => {
    setOpen(false);
  };
  const handleFilterClick = () => {
    setIsDrawerOpen(true);
  };
  const handleFilterClose = () => {
    setIsDrawerOpen(false);
  };
  //API call to get list
  const fetchData = (pageState, setPageState) => {
    let params = `pageNo=${pageState.page}&pageSize=${pageState.pageSize}`;
    if (searchKeyword !== "") {
      const searchParams = `&searchKeyword=${searchKeyword}`;
      params += `${searchParams}`;
    }
    if (startDate !== "" && endDate !== "") {
      const dateParams = `&startDate=${startDate}&endDate=${endDate}`;
      params += `${dateParams}`;
    }
    if (isActive !== "") {
      const filterParams = `&isActive=${isActive}`;
      params += `${filterParams}`;
    }
    if (osName !== "") {
      const osParams = `&osName=${osName}`;
      params += `${osParams}`;
    }
    setPageState((old) => ({ ...old, isLoading: true }));
    const url = `/admin/api/get/appNoticeList?${params}`;
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(url)
        .then((response) => {
          resolve(
            setPageState((old) => ({
              ...old,
              isLoading: false,
              data: response.data.appNoticeListDTOS,
              total: response.data.totalElements,
            }))
          );
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  // function to Delete specific alert
  const handleDeleteIconClick = (rowData) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this alert notice ?"
    );
    if (confirmDelete) {
      const appNoticeId = rowData.row.id;
      axiosInstance
        .delete(`/admin/api/appNoticeDelete/${appNoticeId}`)
        .then((response) => {
          setGridKey((prevKey) => prevKey + 1); // Refresh the grid or update data
        })
        .catch((error) => {
          console.error("Error deleting entry:", error);
        });
    }
  };

  const clearFilters = () => {
    setStartDate("");
    setEndDate("");
    setSearchKeyword("");
    setIsActive("");
    setOsName("");
    setGridKey((prevKey) => prevKey + 1);
  };

  const handleSearch = () => {
    if (searchKeyword || osName || isActive) {
      setGridKey((prevKey) => prevKey + 1);
    } else {
      if (startDate !== "" && endDate !== "") {
        var [day, month, year] = startDate.split("-");
        const stDate = new Date(year, parseInt(month) - 1, parseInt(day));
        [day, month, year] = endDate.split("-");
        const enDate = new Date(year, parseInt(month) - 1, parseInt(day));

        if (stDate > enDate) {
          handleDialogOpen(
            "Date Error",
            "End date must be greater than Start date."
          );
        } else {
          setGridKey((prevKey) => prevKey + 1);
        }
      } else if (
        (startDate === "" && endDate !== "") ||
        (startDate !== "" && endDate === "")
      ) {
        handleDialogOpen(
          "Date Error",
          "Please select both start and end dates."
        );
      } else {
        handleDialogOpen("Search Error", "Please provide search criteria.");
      }
    }
    handleFilterClose();
  };

  const handleSearchOnEnter = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  // condition to update Status
  const toggleStatus = (appNoticeId, currentStatus, alert, setAlert) => {
    const updatedStatus = !currentStatus;
    const isActiveValue = updatedStatus ? "true" : "false";
    axiosInstance
      .patch(
        `/admin/api/appNoticeUpdateStatus/${appNoticeId}?isActive=${isActiveValue}`
      )
      .then((response) => {
        const updatedRows = alert.map((row) =>
          row.id === appNoticeId ? { ...row, isActive: updatedStatus } : row
        );
        setAlert(updatedRows);
        setGridKey((prevKey) => prevKey + 1);
      })
      .catch((error) => {
        console.error(
          "There was an error deleting the alert notice. Please try again.",
          error
        );
      });
  };

  //set the date format
  const handleStartDate = (date) => {
    const sd = StandardDate(date);
    setStartDate(sd);
  };
  //set the date format
  const handleEndDate = (date) => {
    const ed = StandardDate(date);
    setEndDate(ed);
  };
  // Rendering Alert data of list
  const gridColumns = [
    {
      headerName: (
        <Typography variant="h10" fontWeight="bold">
          Title
        </Typography>
      ),
      field: "title",
      width: 100,
    },
    {
      headerName: (
        <Typography variant="h10" fontWeight="bold">
          Message
        </Typography>
      ),
      field: "appNoticeMessage",
      width: 170,
    },
    {
      headerName: (
        <Typography variant="h10" fontWeight="bold">
          Image
        </Typography>
      ),
      field: "image",
      renderCell: (rowData) =>
        rowData.row.image ? (
          <ExpandableImage
            thumbnailSrc={rowData.row.image}
            fullSizeSrc={rowData.row.image}
            alt="Alert Notice Image"
          />
        ) : (
          <span sx={{ color: "red" }}>No Image Uploaded</span>
        ),
      width: 120,
    },
    {
      headerName: (
        <Typography variant="h10" fontWeight="bold">
          OS Type
        </Typography>
      ),
      field: "osName",
      width: 100,
    },
    {
      headerName: (
        <Typography variant="body2" fontWeight="bold">
          Android Min Version
        </Typography>
      ),
      field: "androidMinVersion",
      width: 100,
      renderCell: (params) => {
        const value = Array.isArray(params.value)
          ? params.value.join(", ")
          : params.value;
        return (
          <Tooltip title={value || "N/A"} arrow>
            <Typography
              variant="body2"
              style={{ color: value ? "inherit" : "red" }}
            >
              {value || "N/A"}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      headerName: (
        <Typography variant="body2" fontWeight="bold">
          IOS Min Version
        </Typography>
      ),
      field: "iosMinVersion",
      width: 100,
      renderCell: (params) => {
        const value = Array.isArray(params.value)
          ? params.value.join(", ")
          : params.value;
        return (
          <Tooltip title={value || "N/A"} arrow>
            <Typography
              variant="body2"
              style={{ color: value ? "inherit" : "red" }}
            >
              {value || "N/A"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      headerName: (
        <Typography variant="h10" fontWeight="bold">
          Start Date
        </Typography>
      ),
      field: "startDate",

      width: 100,
    },
    {
      headerName: (
        <Typography variant="h10" fontWeight="bold">
          End Date
        </Typography>
      ),
      field: "endDate",
      width: 100,
    },
    {
      headerName: (
        <Typography variant="h10" fontWeight="bold">
          Created Date
        </Typography>
      ),
      field: "createdAt",
      renderCell: (rowData) => {
        const created_date = rowData?.row?.createdAt;
        return <DateFormatter dateString={created_date} />;
      },
      width: 150,
    },
    {
      headerName: (
        <Typography variant="h10" fontWeight="bold">
          Status
        </Typography>
      ),
      field: "isActive",
      renderCell: (rowData) => {
        const handleStatusChange = () => {
          const idToUpdate = rowData.row.id;
          const newStatus = rowData.row.isActive;
          if (accessType !== "READ") {
            // Check accessType before toggling status
            toggleStatus(idToUpdate, newStatus, alert, setAlert);
          }
        };

        return (
          <Tooltip title={rowData.accessType === "READ" ? "Access Denied" : ""}>
            <span>
              <Switch
                checked={rowData.row.isActive}
                onChange={() => handleStatusChange(rowData)}
                color="primary"
                disabled={accessType === "READ"} // Disable if accessType is "READ"
              />
            </span>
          </Tooltip>
        );
      },
      width: 70,
    },
    {
      headerName: (
        <Typography variant="h10" fontWeight="bold">
          Edit
        </Typography>
      ),
      renderCell: (rowData) => {
        return (
          <Tooltip
            title={accessType === "READ" ? "Access Denied" : ""}
            placement="top"
            arrow
          >
            <div style={{ display: "inline-block" }}>
              <IconButton
                onClick={() => handleEditIconClick(rowData)}
                disabled={accessType === "READ"}
              >
                <EditIcon
                  sx={{ color: accessType === "READ" ? "green" : "green" }}
                />
              </IconButton>
            </div>
          </Tooltip>
        );
      },
      width: 50,
    },
    {
      headerName: (
        <Typography variant="h10" fontWeight="bold">
          Delete
        </Typography>
      ),
      field: "is_soft_delete",
      renderCell: (rowData) => (
        <Tooltip
          title={accessType === "READ" ? "Access Denied" : ""}
          placement="top"
          arrow
        >
          <div style={{ display: "inline-block" }}>
            <IconButton
              onClick={() => handleDeleteIconClick(rowData)}
              disabled={accessType === "READ"}
            >
              <DeleteIcon
                sx={{ color: accessType === "READ" ? "red" : "red" }}
              />
            </IconButton>
          </div>
        </Tooltip>
      ),
      width: 65,
    },
  ];
  // show the  messages
  useEffect(() => {
    if (message || error) {
      const timer = setTimeout(() => {
        setMessage("");
        setError("");
      }, 4000);

      return () => {
        clearTimeout(timer);
        setMessage("");
        setError("");
      };
    }
  }, [message, error]);

  //   screen design (JSX)
  return (
    <div className="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <div className="body flex-grow-1 px-3">
        <AppContent />
        <Grid container item lg={12} md={12} sm={12} spacing={2} mb={1}>
          <Grid item xs={6} sm={6} md={4} lg={6} mt={1}>
            <h5>Alert Notice</h5>
          </Grid>
          <Hidden mdUp smUp>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {searchKeyword || startDate || endDate || osName || isActive ? (
                  <IconButton onClick={clearFilters} title="Clear all filters">
                    <img
                      src={CleanIcon}
                      alt="Clear"
                      style={{
                        width: "19px",
                        height: "24px",
                        color: "#007BFF",
                      }}
                    />
                  </IconButton>
                ) : null}
                <Button
                  onClick={handleFilterClick}
                  startIcon={<FilterListIcon />}
                >
                  Filters
                </Button>
              </div>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={6} md={7} lg={6}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {!isSmallScreen && (
                <Grid
                  item
                  xs={5}
                  sm={3}
                  md={3}
                  lg={2}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Tooltip title={accessType === "READ" ? "Access Denied" : ""}>
                    <span>
                      <CustomButton
                        value="Add"
                        onClick={() => {
                          if (accessType !== "READ") {
                            addHandleClose();
                          }
                        }}
                        disabled={accessType === "READ"}
                      />
                    </span>
                  </Tooltip>
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={7} lg={6}>
                <TextField
                  type="text"
                  placeholder="Search..."
                  value={searchKeyword}
                  onChange={(e) => setSearchKeyword(e.target.value)}
                  onKeyPress={handleSearchOnEnter}
                  InputProps={{
                    style: {
                      height: "46px",
                      width: "100%",
                    },
                  }}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "56px", // Add border radius here
                      "&:hover fieldset": {
                        borderColor: "#3f1e81",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#3f1e81",
                      },
                    },
                  }}
                />
              </Grid>

              <Grid
                item
                xs={5}
                sm={3}
                md={2}
                lg={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                <CustomButton
                  onClick={handleSearch}
                  height="40px"
                  width="55px"
                  margin="5px"
                  startIcon={<SearchIcon />}
                />
                {!isSmallScreen &&
                  (searchKeyword ||
                    startDate ||
                    endDate ||
                    osName ||
                    isActive) && (
                    <IconButton
                      onClick={clearFilters}
                      title="Clear all filters"
                    >
                      <img
                        src={CleanIcon}
                        alt="Clear"
                        style={{
                          width: "19px",
                          height: "24px",
                          color: "#007BFF",
                        }}
                      />
                    </IconButton>
                  )}
              </Grid>

              {!isSmallScreen && (
                <Grid
                  item
                  xs={2.5}
                  sm={2.5}
                  md={2.7}
                  lg={2.7}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    onClick={handleFilterClick}
                    startIcon={<FilterListIcon />}
                  >
                    Filters
                  </Button>
                </Grid>
              )}
              <Hidden smUp mdUp>
                <Tooltip title={accessType === "READ" ? "Access Denied" : ""}>
                  <span>
                    <CustomButton
                      value="Add"
                      onClick={() => {
                        if (accessType !== "READ") {
                          addHandleClose();
                        }
                      }}
                      disabled={accessType === "READ"}
                    />
                  </span>
                </Tooltip>
              </Hidden>
            </div>
          </Grid>
        </Grid>
        <Paper
          elevation={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem",
          }}
        >
          {/* Table component */}
          <GridComponent
            key={gridKey}
            columns={gridColumns}
            fetchData={fetchData}
            gridName="AlertNotice"
          />
          {/* Add alert Modal */}
          <AddAlertNotice
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            addHandleClose={addHandleClose}
            gridKey={gridKey}
            setGridKey={setGridKey}
          />
          {/* Update alert Modal */}
          <UpdateAlertNotice
            open={isEditModalOpen}
            onClose={() => setIsEditModalOpen(false)}
            handleClose={handleClose}
            gridKey={gridKey}
            selectedRowData={selectedRowData}
            setGridKey={setGridKey}
          />
        </Paper>
      </div>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleFilterClose}
        PaperProps={{
          sx: {
            width: 350,
            height: "auto",
            maxHeight: "80%",
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
            padding: 3,
            overflow: "auto",
            top: "60px",
          },
        }}
      >
        <Box>
          <Typography id="filter-modal-title" variant="h6" component="h2">
            Filters
          </Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={<div style={{ margin: "5px" }}>Start Date</div>}
                  onChange={handleStartDate}
                  value={
                    startDate !== "" ? dayjs(startDate, "DD-MM-YYYY") : null
                  }
                  format="DD-MM-YYYY"
                  maxDate={today}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={<div style={{ margin: "5px" }}>End Date</div>}
                  onChange={handleEndDate}
                  value={endDate !== "" ? dayjs(endDate, "DD-MM-YYYY") : null}
                  format="DD-MM-YYYY"
                  maxDate={today}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel
                  id="searchByActiveStatus-label"
                  style={{ margin: "5px" }}
                >
                  Status
                </InputLabel>
                <Select
                  labelId="searchByActiveStatus-label"
                  id="searchByActiveStatus"
                  value={isActive}
                  onChange={(e) => setIsActive(e.target.value)}
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                sx={{ minWidth: 50, marginBottom: "20px" }}
              >
                <InputLabel sx={{ margin: "5px" }}>OS Type </InputLabel>
                <Select
                  name="osName"
                  value={osName}
                  onChange={(event) => setOsName(event.target.value)}
                >
                  {osType.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
            {startDate || endDate || osName || isActive ? (
              <IconButton onClick={clearFilters} title="Clear all filters">
                <img
                  src={CleanIcon}
                  alt="Clear"
                  style={{ width: "19px", height: "24px", color: "#007BFF" }}
                />
              </IconButton>
            ) : null}
            <Button onClick={handleFilterClose} variant="contained" style={{ background: "red", color: "white" , marginRight: "10px", }}>
              Close
            </Button>

            <Button onClick={handleSearch} variant="contained" sx={{ ml: 2 }} style={{ background: "green", color: "white" , marginRight: "10px", }}>
              Apply
            </Button>
          </Box>
        </Box>
      </Drawer>
      <AlertModal
        open={open}
        title={dialogTitle}
        content={dialogContent}
        onClose={handleDialogClose}
      />
    </div>
  );
};

export default AlertNotice;
