import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  Grid,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Box,
  IconButton,
  FormHelperText,
} from "@mui/material";
import axiosInstance from "../../../api/interceptors/axiosInstance";
import { ADD_ALERT_NOTICE } from "../../../utils/constants/api_constant";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ClearIcon from "@mui/icons-material/Clear";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ImageIcon from "@mui/icons-material/Image";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import StandardDate from "../StandardDate";

const AddAlertNotice = ({
  open,
  onClose,
  addHandleClose,
  rowData,
  handleSaveClick,
  gridKey,
  setGridKey,
}) => {
  const [error, setError] = useState("");
  const [title, setTitle] = useState("");
  const [routeType, setRouteType] = useState("");
  const [message, setMessage] = useState("");
  const [appNoticeMessage, setAppNoticeMessage] = useState("");
  const [osName, setOsName] = useState("ALL");
  const [androidMinVersion, setAndroidMinVersion] = useState("");
  const [iosMinVersion, setIosMinVersion] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [description, setDescription] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // or "error" depending on the severity
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [uploadedImage, setUploadedImage] = useState(null);
  const [androidError, setAndroidError] = useState(false);
  const [iosError, setIosError] = useState(false);
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [appNoticeMessageError, setAppNoticeMesasgeError] = useState("");

  const handleOsChange = (event) => {
    setOsName(event.target.value);
  };

  const osType = ["ALL", "ANDROID", "IOS"];
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    routeType: "",
    osName: "",
    androidMinVersion: "",
    iosMinVersion: "",
    file: "",
    startDate: null, // Set a default value (e.g., null or an empty string)
    endDate: null,
    message: "",
    appNoticeMessage: "",
  });

  useEffect(() => {
    if (message || error) {
      const timer = setTimeout(() => {
        setMessage("");
        setError("");
      }, 2000);

      return () => {
        clearTimeout(timer);
        setMessage("");
        setError("");
      };
    }
  }, [message, error]);

  //set the date format
  const handleStartDate = (date) => {
    const sd = StandardDate(date);
    setStartDate(sd);
  };
  //set the date format
  const handleEndDate = (date) => {
    const ed = StandardDate(date);
    setEndDate(ed);
  };
  //Message SnackBar
  const handleOpenSnackbar = (severity, message) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  // File Changes
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUploadedImage(file);
  };
  //API call for Add Alert
  const submitHandler = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    const data = [
      "title",
      "routeType",
      "description",
      "appNoticeMessage",
      "osName",
    ];
    // Loop through the fields and append them to formData
    data.forEach((field) => {
      const value = event?.target?.[field]?.value;
      if (value !== undefined) {
        formData.append(field, value);
      }
    });
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("iosMinVersion", iosMinVersion);
    formData.append("androidMinVersion", androidMinVersion);
    if (uploadedImage) {
      formData.append("image", uploadedImage);
    }
    try {
      const response = await axiosInstance.post(
        `${ADD_ALERT_NOTICE}`,
        formData
      );
      handleOpenSnackbar("success", response.data.message); // Show success message
      setTimeout(() => {
        setGridKey((prevKey) => prevKey + 1);
        onClose();
        resetAndClose();
      }, 2000);
    } catch (error) {
      console.error("Error adding Alert Notice data:", error);

      if (error.response && error.response.data) {
        setError(error.response.data.message);
        setTimeout(() => {
          setError("");
        }, 5000);
      } else {
        setMessage("");
        setError(error.errorMessage || error?.response?.data?.message);
        setTimeout(() => {
          setError("");
        }, 5000);
      }
    }
  };

  // Reset Input onClose
  const resetAndClose = () => {
    setTitle("");
    setRouteType("");
    setAndroidMinVersion("");
    setIosMinVersion("");
    setStartDate("");
    setEndDate("");
    setAppNoticeMessage("");
    setDescription("");
    setUploadedImage(null);
    setTitleError("");
    setDescriptionError("");
    setOsName("ALL");
    setAppNoticeMesasgeError("");
    onClose();
  };
  // Validation function for Android version
  const validateVersion = (version) => {
    const regex = /^(?!0{1,}\.0{1,}\.0{1,}$)\d{1,2}\.\d{1,2}\.\d{1,2}$/;
    if (!regex.test(version)) {
      return false;
    }
    return true;
  };

  //Validating  andriod version
  const handleAndroidChange = (e) => {
    const value = e.target.value;
    setAndroidMinVersion(value);
    const versions = value.split(",").map((version) => version.trim());
    const invalidVersions = versions.filter(
      (version) => !validateVersion(version)
    );
    if (invalidVersions.length > 0) {
      setAndroidError(
        "Invalid format. Please use the format X.X.X, where a comma is used to separate each version."
      );
    } else {
      setAndroidError("");
    }
  };
  //Validating  iOs Version
  const handleIosChange = (e) => {
    const value = e.target.value;
    setIosMinVersion(value);
    const versions = value.split(",").map((version) => version.trim());
    const invalidVersions = versions.filter(
      (version) => !validateVersion(version)
    );
    if (invalidVersions.length > 0) {
      setIosError(
        "Invalid format. Please use the format X.X.X, where a comma is used to separate each version."
      );
    } else {
      setIosError("");
    }
  };

  //   screen design (JSX)
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog open={open} maxWidth="md" fullWidth>
        <DialogContent>
          <form onSubmit={submitHandler}>
            <Typography variant="h6" gutterBottom>
              Add Alert Notice
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  fullWidth
                  label="Title"
                  variant="outlined"
                  value={title}
                  name="title"
                  margin="normal"
                  required
                  InputLabelProps={{ style: { marginTop: "4px" } }}
                  onChange={(e) => {
                    let value = e.target.value;

                    const trimmedValue = value.trim();

                    if (trimmedValue.length >= 3 && trimmedValue.length <= 50) {
                      setTitle(value);
                      setTitleError(false);
                    } else {
                      setTitle(value);
                      setTitleError(true);
                    }
                  }}
                  error={titleError}
                  helperText={
                    titleError
                      ? "Title must be between 3 and 50 characters and cannot be only spaces"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  fullWidth
                  label="Route Type"
                  variant="outlined"
                  value={routeType}
                  name="routeType"
                  onChange={(e) => setRouteType(e.target.value)}
                  margin="normal"
                  InputLabelProps={{ style: { marginTop: "4px" } }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "1px" }}>
              <Grid item xs={12} sm={4}>
                <FormControl
                  fullWidth
                  sx={{ minWidth: 50, marginBottom: "20px" }}
                >
                  <InputLabel sx={{ margin: "3px" }}>OS Type *</InputLabel>
                  <Select
                    name="osName"
                    value={osName}
                    onChange={handleOsChange}
                    required
                  >
                    {osType.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {osName === "ALL" ? (
                <Grid item xs={12} sm={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        sx={{ minWidth: 50, marginBottom: "20px" }}
                      >
                        <TextField
                          label="Android Version"
                          type="text"
                          name="androidMinVersion"
                          value={androidMinVersion}
                          onChange={handleAndroidChange}
                          error={Boolean(androidError)}
                          helperText={androidError}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        sx={{ minWidth: 50, marginBottom: "20px" }}
                      >
                        <TextField
                          label="iOS Version"
                          type="text"
                          name="iosMin"
                          value={iosMinVersion}
                          onChange={handleIosChange}
                          error={Boolean(iosError)}
                          helperText={iosError}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <>
                  {osName === "ANDROID" && (
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        sx={{ minWidth: 50, marginBottom: "20px" }}
                      >
                        <TextField
                          label="Android Version"
                          type="text"
                          name="androidMinVersion"
                          value={androidMinVersion}
                          onChange={handleAndroidChange}
                          error={Boolean(androidError)}
                          helperText={androidError}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {osName === "IOS" && (
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        sx={{ minWidth: 50, marginBottom: "20px" }}
                      >
                        <TextField
                          label="iOS Version"
                          type="text"
                          name="iosMin"
                          value={iosMinVersion}
                          onChange={handleIosChange}
                          error={Boolean(iosError)}
                          helperText={iosError}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <style>
                    {`
                        .hide-asterisk .MuiFormLabel-asterisk {
                        display: none;
                      }
                     `}
                  </style>
                  <DatePicker
                    disablePast
                    renderInput={(props) => (
                      <TextField {...props} fullWidth label="Select Date" />
                    )}
                    value={formData.startDate}
                    label={<div style={{ margin: "5px" }}>Start Date*</div>}
                    onChange={handleStartDate}
                    format="DD-MM-YYYY"
                    fullWidth
                    helperText={
                      formData.startDate ? null : "Please select a date"
                    }
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        required: true,
                        className: "hide-asterisk",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disablePast
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        fullWidth
                        label="Select Date"
                        required
                        helperText="Please select a date"
                      />
                    )}
                    value={formData.endDate}
                    label={<div style={{ margin: "5px" }}>End Date*</div>}
                    onChange={handleEndDate}
                    format="DD-MM-YYYY"
                    fullWidth
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        required: true,
                        className: "hide-asterisk",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={3} xl={2}>
                <input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />

                <label htmlFor="contained-button-file">
                  <Button variant="contained" component="span">
                    Upload <ImageIcon />
                  </Button>
                </label>
                {uploadedImage === null && (
                  <FormHelperText style={{ display: "block" }}>
                    Please upload an image
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                {uploadedImage && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <img
                      src={URL.createObjectURL(uploadedImage)}
                      // alt="Uploaded Banner"
                      style={{
                        maxWidth: "50%",
                        maxHeight: "50%",
                        width: "auto",
                      }}
                    />
                    <IconButton
                      onClick={() => setUploadedImage(null)}
                      sx={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={1}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  name="description"
                  label={<div style={{ margin: "5px" }}>Description*</div>}
                  value={description}
                  multiline
                  rows={3}
                  fullWidth
                  onChange={(e) => {
                    let value = e.target.value;
                    const trimmedValue = value.trim();
                    if (trimmedValue.length >= 10 && trimmedValue.length <= 255) {
                      setDescription(value);
                      setDescriptionError(false);
                    } else {
                      setDescription(value);
                      setDescriptionError(true);
                    }
                  }}
                  error={descriptionError}
                  helperText={
                    descriptionError
                      ? "Description must be between 10 and 255 characters"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  name="appNoticeMessage"
                  label={<div style={{ margin: "5px" }}>Message*</div>}
                  value={appNoticeMessage}
                  multiline
                  rows={3}
                  fullWidth
                  onChange={(e) => {
                    let value = e.target.value;
                    const trimmedValue = value.trim();
                    if (trimmedValue.length >= 10 && trimmedValue.length <= 255) {
                      setAppNoticeMessage(value);
                      setAppNoticeMesasgeError(false);
                    } else {
                      setAppNoticeMessage(value);
                      setAppNoticeMesasgeError(true);
                    }
                  }}
                  error={appNoticeMessageError}
                  helperText={
                    appNoticeMessageError
                      ? "App Notice Message must be between 10 and 255 characters"
                      : ""
                  }
                />
              </Grid>
            </Grid>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <span style={{ color: "green", fontWeight: "bold" }}>
                {message ? message : ""}
              </span>
              <span style={{ color: "red", fontWeight: "bold" }}>
                {error ? error : ""}
              </span>
            </div>
            &nbsp;
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                style={{
                  background: "red",
                  color: "white",
                  marginRight: "10px",
                }}
                onClick={() => {
                  resetAndClose();
                }}
              >
                Close
              </Button>
              <Button
                type="submit"
                style={{ background: "green", color: "white" }}
                onClick={handleSaveClick}
              >
                Save
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={snackbarSeverity}
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </LocalizationProvider>
  );
};

export default AddAlertNotice;
