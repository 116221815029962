import React from "react";
import CIcon from "@coreui/icons-react";
import { CNavItem, CNavGroup } from "@coreui/react";
const _nav = [
  {
    component: CNavItem,
    name: "",
    to: "",
    icon: (
      <CIcon
        icon=""
        customClassName="nav-icon"

      />
    ),
    style: { color: "#fff" }, // Text color
  },
  

];

export default _nav;
