import AlertNotice from "../../pages/AlertNotice";
import CustomerFeedback from "../../pages/CustomerFeedback";

export const table_unique_key = {
  Transactions: "transactionId",
  Customers: "userId",
  Commission: "id",
  Version: "id",
  Wallet: "id",
  Instrument: "id",
  Banners: "id",
  Menu: "id",
  Discount: "id",
  Services: "serviceId", 
  Instruments:"menuCategory",
  BulkRefund:"bulkRefundId",
  UserPermission:"userId",
  ApiMonitor:"id",
  GetCustomerTickets:"ticketId",
  DiscountRestriction:"id",
  ApiLogs: "id",
  Rewards: "id",
  CustomersFeedback :"id",
  AlertNotice : "id",

};
