import React, { useState, useEffect } from "react";
import { AppContent, AppHeader } from "../components/navbar";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import GridComponent from "../components/common/Grid";
import { useParams } from "react-router-dom";
import axiosInstance from "../api/interceptors/axiosInstance";
import AddModal from "../components/common/masterData/AddModal";
import EditMasterModal from "../components/common/masterData/EditMasterModal";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Switch from "@mui/material/Switch";
import { Tooltip } from "@mui/material";
import CustomButton from "../components/common/CustomButton";

const MasterData = ({ accessType }) => {
  const params = useParams();
  const { slug = "" } = params;
  const [gridData, setGridData] = useState([]);
  const [masters, setMasters] = useState([]);
  const [gridKey, setGridKey] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const osOptions = ["ALL", "ANDROID", "IOS"];
  const handleEditIconClick = (rowData) => {
    setSelectedRowData(rowData);
    setIsEditModalOpen(true);
  };
  const handleClose = () => {
    setIsEditModalOpen(false); // Set the open state to false to close the modal
  };
  const handleOpen = () => {
    setIsModalOpen(true);
  };
  const addHandleClose = () => {
    setIsModalOpen(false);
  };

  const handleToggleStatus = (rowData) => {
    const idToToggle = rowData.row.id;
    const currentStatus = rowData.row.isActive;

    // Call the function to toggle the status
    toggleMasterStatus(idToToggle, currentStatus);
  };

  const toggleMasterStatus = (id, currentStatus) => {
    const updatedStatus = !currentStatus; // Toggle the current status
    const isActiveValue = updatedStatus ? "false" : "true"; // Convert boolean to string for isActive query parameter
    axiosInstance
      .patch(`/admin/api/type-masters/${id}?isActive=${isActiveValue}`) // Use PATCH request with the provided URL format
      .then((response) => {
        const updatedMaster = masters.map((masters) =>
          masters.id === id ? { ...masters, isActive: updatedStatus } : masters
        );
        setMasters(updatedMaster);
        setGridKey((prevKey) => prevKey + 1);
      })
      .catch((error) => {
        console.error("Error updating Master status:", error);
        if (error.response && error.response.data) {
          const errorMessage = error.response.data.message;
          // Update the state to display the error message
          setError(errorMessage);
          setTimeout(() => {
            setError("");
          }, 2000);
        } else {
          setError("An error occurred while updating the Master status.");
          setTimeout(() => {
            setError("");
          }, 2000);
        }
      });
  };

  const ExpandableImage = ({ thumbnailSrc, fullSizeSrc, alt }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleImageClick = () => {
      setIsExpanded(!isExpanded);
    };
    // Check if the close icon was clicked before closing the modal
    const handleClose = (event, reason) => {
      if (reason === "escapeKeyDown" || reason === "backdropClick") {
        return;
      }
      setIsExpanded(false);
    };
    return (
      <div>
        <img
          src={thumbnailSrc}
          alt={alt}
          style={{ maxWidth: "100px", maxHeight: "100px", cursor: "pointer" }}
          onClick={handleImageClick}
        />
        <Dialog open={isExpanded} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>
            {alt}
            <IconButton
              edge="end"
              color="inherit"
              onClick={(event) => handleClose(event, "iconClick")}
              aria-label="close"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                marginRight: "2px",
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <img
              src={fullSizeSrc}
              alt={alt}
              style={{ width: "100%", height: "60%" }}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  const gridColumns = [
    {
      headerName: (
        <Typography variant="h10" fontWeight="bold">
          Name
        </Typography>
      ),
      field: "externalName",
      width: 200,
    },
    {
      headerName: (
        <Typography variant="h10" fontWeight="bold">
          OS Type
        </Typography>
      ),
      field: "platForm",
      width: 200,
    },

    {
      headerName: (
        <Typography variant="h10" fontWeight="bold">
          Image
        </Typography>
      ),
      field: "image",
      renderCell: (rowData) =>
        rowData.row.image ? (
          <div style={{ width: "70px", height: "auto" }}>
            <ExpandableImage
              thumbnailSrc={rowData.row.image}
              fullSizeSrc={rowData.row.image}
              alt="Master Data Image"
              style={{ width: "70%", height: "70%" }}
            />
          </div>
        ) : (
          <span style={{ color: "red" }}>No Image Uploaded</span>
        ),
      width: 250,
    },

    {
      headerName: (
        <Typography variant="h10" fontWeight="bold">
          Status
        </Typography>
      ),
      field: "isActive",
      renderCell: (rowData) => {
        const handleStatusChange = (rowData) => {
          const idToUpdate = rowData.row.id;
          const newStatus = !rowData.row.isActive;

          toggleMasterStatus(idToUpdate, newStatus);
        };

        return (
          <Switch
            checked={rowData.row.isActive}
            onChange={() => handleStatusChange(rowData)}
            color="primary"
          />
        );
      },
      width: 150,
    },
    {
      // Edit column
      headerName: (
        <Typography variant="h10" fontWeight="bold">
          Edit
        </Typography>
      ),
      renderCell: (rowData) => {
        return (
          <IconButton onClick={() => handleEditIconClick(rowData)}>
            <EditIcon sx={{ color: "green" }} />
          </IconButton>
        );
      },
      width: 80,
    },
    {
      // Delete column
      headerName: (
        <Typography variant="h10" fontWeight="bold">
          Delete
        </Typography>
      ),
      field: "is_soft_delete",
      renderCell: (rowData) => (
        <IconButton onClick={() => handleDeleteIconClick(rowData)}>
          <DeleteIcon sx={{ color: "red" }} />
        </IconButton>
      ),
      width: 75,
    },
  ];

  useEffect(() => {
    setGridKey(gridKey + 1);
  }, [slug]);

  const fetchData = (pageState, setPageState) => {
    let params = `pageNo=${pageState.page}&pageSize=${pageState.pageSize}`;

    setPageState((old) => ({ ...old, isLoading: true }));
    const url = `/admin/api/type-masters/get-all?parent=${slug}&${params}`;
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(url)
        .then((response) => {
          if (
            Array.isArray(response.data.typemasterList) &&
            response.data.typemasterList.length > 0
          ) {
            const mappedData = response.data.typemasterList.map(
              (item, index) => ({
                id: index + 1,
                externalName: item.externalName,
                slug: item.slug,
              })
            );

            setGridData(mappedData);
          }
          resolve(
            setPageState((old) => ({
              ...old,

              isLoading: false,
              data: response.data.typemasterList,
              total: response.data.totalElements,
            }))
          );
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  const handleDeleteIconClick = (rowData) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this entry?"
    );
    if (confirmDelete) {
      const idToDelete = rowData.row.id; // Get the id of the selected row
      // Send a DELETE request to your API with the id to delete the entry
      axiosInstance
        .delete(`/admin/api/type-masters/${idToDelete}`)
        .then((response) => {
          setGridKey((prevKey) => prevKey + 1); // Refresh the grid or update data
        })
        .catch((error) => {
          console.error("Error deleting entry:", error);
        });
    }
  };

  return (
    <div>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <AppContent />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5>Master Data</h5>

            <Tooltip title={accessType === "READ" ? "Access Denied" : ""}>
              <span>
                <CustomButton
                  value="Add Master Data"
                  onClick={() => {
                    if (accessType !== "READ") {
                      handleOpen();
                    }
                  }}
                  disabled={accessType === "READ"}
                />
              </span>
            </Tooltip>
          </div>

          <Paper
            elevation={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "1rem",
              marginTop: "0.5rem",
            }}
          >
            <GridComponent
              // rows={gridData}
              columns={gridColumns}
              fetchData={fetchData}
              gridName="Instrument"
              key={gridKey}
            />
            <div>
              <AddModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                addHandleClose={addHandleClose}
                gridKey={gridKey}
                setGridKey={setGridKey}
                slug={slug}
              />
            </div>
            <div>
              <EditMasterModal
                open={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                rowData={selectedRowData}
                osOptions={osOptions}
                handleClose={handleClose}
                gridKey={gridKey}
                setGridKey={setGridKey}
              />
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default MasterData;
