import React from "react";

const Offline = () => {
  return (
    <div className="offline">
      <h1>You are currently offline</h1>
      <p>
        Please check your internet connection and try again. While offline, you can still view
        some of the content, but you won't be able to perform certain actions that require an
        internet connection.
      </p>
    </div>
  );
};

export default Offline;
