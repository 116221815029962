import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilMenu } from '@coreui/icons';
import { AppBreadcrumb } from './index';
import { AppHeaderDropdown } from './header/index';
import { toggleHamburger } from '../../store/slices/HamburgerSlice';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

const vars = {
  '--cui-sidebar-bg': 'red',
};

const AppHeader = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.HamburgerReducer.sidebarShow);
  const location = useLocation();
  const [isDashboardPage, setIsDashboardPage] = useState(false);
  const [showInfoAlert, setShowInfoAlert] = useState(true);  // Show alert for testing

  useEffect(() => {
    // Check if the current location is the dashboard page
    setIsDashboardPage(location.pathname === '/dashboard');
  }, [location.pathname]);

  const handleToggleSidebar = () => {
    dispatch(toggleHamburger(!sidebarShow));
  };

  return (
    <CHeader position="sticky" className="mb-2" style={vars}>
      <CContainer fluid>
        <CHeaderToggler className="ps-1" onClick={handleToggleSidebar}>
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          {/* <CIcon icon={logo} height={48} alt="Logo" /> */}
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
          <CNavItem>
            {/* <CNavLink to="/dashboard" component={NavLink}>
              Dashboard
            </CNavLink> */}
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav>
        </CHeaderNav>
        <CHeaderNav className="ms-3">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      {/* <CHeaderDivider /> */}
      {/* <CContainer fluid>
        <AppBreadcrumb />
        {/* {isDashboardPage && (
          <CHeaderNav className="ms-3">
            {showInfoAlert && (
              <Alert severity="info">
                <Typography variant="body2" color="textSecondary">
                  This data is 5 minutes old.
                </Typography>
              </Alert>
            )}
          </CHeaderNav>
        )} 
      </CContainer> */}
    </CHeader>
  );
};

export default AppHeader;
