import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { TextField } from "@mui/material";
import { AppHeader, AppContent } from "../components/navbar";
import CustomButton from "../components/common/CustomButton";
import { fetchProfile } from "../api/GetAdminProfile";
import axiosInstance from "../api/interceptors/axiosInstance";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { logoutapi } from "../api/Login";

const AdminProfile = ({ routeMessage }) => {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [validationError, setValidationError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [openRouteSnackbar, setOpenRouteSnackbar] = useState(false);
  const navigate = useNavigate(); // Initialize navigate

  // Get Profile info
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchProfile();
        setFirstName(data.firstName);
        setEmail(data.email);
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchData();
  }, []);

  const handleOpenSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };
  //Form validation
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  // Form submit for password change
  const handleSubmit = async (e) => {
    e.preventDefault();
    const regex =
      /^(?=.*[0-9])(?=.*[!@#$%^&*()_+=\-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    if (!regex.test(formData.newPassword)) {
      setError(
        "New password must be at least 8 characters long and at least one uppercase, lowercase, alphanumeric & special character."
      );
      return;
    }
    if (formData.newPassword !== formData.confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }
    try {
      const response = await axiosInstance.post(
        "/api/admin/auth/change-password",
        { newPassword: formData.newPassword, oldPassword: formData.oldPassword }
      );
      // Reset form after successful password change
      setFormData({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      handleOpenSnackbar(response.data.message);
      setMessage(response.data.message);
      setError("");
      setPasswordError(""); // Reset password error
      setTimeout(() => {
        setOpenSnackbar(false);
        logout(); // Logout after successful password change
      }, 2000);
    } catch (error) {
      setMessage("");
      setError(error.errorMessage || error?.response?.data?.message);
      // console.error("Error changing password:", error);
    }
  };
  // Logout function
  function logout() {
    logoutapi();
    localStorage.clear();
    navigate("/");
  }
  // Validation of New Password
  useEffect(() => {
    const regex =
      /^(?=.*[0-9])(?=.*[!@#$%^&*()_+=\-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (formData.newPassword && !regex.test(formData.newPassword)) {
      setValidationError(
        "New password must be at least 8 characters long and at least one uppercase, lowercase, alphanumeric & special character."
      );
    } else {
      setValidationError("");
    }

    if (
      formData.confirmPassword &&
      formData.newPassword !== formData.confirmPassword
    ) {
      setPasswordError("Passwords do not match");
    } else {
      setPasswordError("");
    }
  }, [formData.newPassword, formData.confirmPassword]);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError("");
      }, 4000);

      return () => {
        clearTimeout(timer);
        setError("");
      };
    }
  }, [message, error]);

  useEffect(() => {
    if (routeMessage) {
      setOpenRouteSnackbar(true);
      const timer = setTimeout(() => {
        setOpenRouteSnackbar(false);
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [routeMessage]);

  //func to send data
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value.trim(), // Trim spaces
    }));
  };

  return (
    <div className="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <div className="body flex-grow-1 px-3">
        <AppContent />
        <h4>Admin Profile</h4>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <h5>Profile Information</h5>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={7}>
            <div className="bg-white rounded p-4">
              <form>
                <TextField
                  label="Name"
                  className="mb-3"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  InputLabelProps={{ style: { marginTop: "4px" } }}
                  disabled
                />
                <TextField
                  label="Email"
                  className="mb-3"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  InputLabelProps={{ style: { marginTop: "4px" } }}
                  disabled
                />
              </form>
            </div>
          </Grid>

          <Grid item xs={3}>
            <h5>Update Password</h5>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={7}>
            <div className="bg-white rounded p-4">
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Current password"
                  variant="outlined"
                  className="mb-3"
                  fullWidth
                  name="oldPassword"
                  value={formData.oldPassword}
                  onChange={handleChange}
                  size="small"
                  InputLabelProps={{ style: { marginTop: "4px" } }}
                  required
                />
                <TextField
                  label="New password"
                  variant="outlined"
                  fullWidth
                  className="mb-3"
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={handleChange}
                  size="small"
                  InputLabelProps={{ style: { marginTop: "4px" } }}
                  required
                />
                <TextField
                  label="Confirm new password"
                  variant="outlined"
                  fullWidth
                  required
                  className="mb-3"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  error={passwordError !== ""}
                  helperText={passwordError}
                  size="small"
                  InputLabelProps={{ style: { marginTop: "4px" } }}
                />
                <div>
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    {errorMessage ? errorMessage : ""}
                  </span>
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    {error ? error : ""}
                  </span>
                </div>
                <CustomButton
                  variant="contained"
                  color="primary"
                  value="save"
                  type="submit"
                  className="mt-3"
                />
              </form>
            </div>
          </Grid>
        </Grid>
      </div>
      {routeMessage && (
        <Snackbar
          open={openRouteSnackbar}
          autoHideDuration={2000}
          onClose={() => setOpenRouteSnackbar(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => setOpenRouteSnackbar(false)}
            severity="error"
          >
            {routeMessage}
          </MuiAlert>
        </Snackbar>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setOpenSnackbar(false)}
          severity="success"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default AdminProfile;
