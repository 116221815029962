// NotFound.js
import React from 'react';
import { Grid } from '@mui/material';
import {  AppHeader } from '../components/navbar';

const NotFound = () => {
  return (
    <Grid container direction="column" className="wrapper min-vh-100 bg-light">
      <Grid item>
        <AppHeader />
      </Grid>
      <Grid item container justifyContent="center" className="body">
        <div className="offline">
          <h3 style={{ color: 'red', marginTop: '20px' }}>Access Denied</h3>
          <p  style={{marginLeft:"-4em"}}>Please contact the administrator for access.</p>
        </div>
      </Grid>
    </Grid>
  );
};

export default NotFound;
