import axiosInstance from "../api/interceptors/axiosInstance";
export const fetchRoles = async () => {

  try {
    const response = await axiosInstance.get("admin/api/role/get-all-roles");
    return response.data;
  } catch (error) {
    console.error("Error fetching API data:", error);
    throw error; // Rethrow the error to handle it in the component
  }
};
