import {
  Dialog,
  DialogContent,
  TextField,
  Box,
  Typography,
  Button,
  IconButton,
  Grid,
  MenuItem,
} from "@mui/material";
import { useState, useEffect } from "react";
import axiosInstance from "../../../api/interceptors/axiosInstance";
import ClearIcon from "@mui/icons-material/Clear";

const EditMasterModal = ({
  open,
  handleClose,
  onClose,
  rowData,
  osOptions,
  gridKey,
  setGridKey,
}) => {
  const [platForm, setPlatForm] = useState([]);
  const [externalName, setExternalName] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [masterData, setMasterData] = useState({
    id: "",
    externalName: "",
    platForm: "",
    file: "",
  });
  const [imagePath, setImagePath] = useState("");
  useEffect(() => {
    if (rowData) {
      setMasterData({
        id: rowData.row.id || "",
        externalName: rowData.row.externalName || "",
        platForm: rowData.row.platForm || "",
        file: rowData.row.file || "",
      });
      setImagePath(rowData.row.image || "");
    }
  }, [rowData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setMasterData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // If the name is "platform", update the local state directly
    if (name === "platForm") {
      setPlatForm(value);
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append("id", rowData.row.id);
    formDataToSend.append("externalName", masterData.externalName);
    formDataToSend.append("platForm", masterData.platForm);
    if (uploadedImage) {
      formDataToSend.append("file", uploadedImage);
    }
    try {
      const response = await axiosInstance.put(
        `admin/api/type-masters`,
        formDataToSend
      );
      setMessage(response.data.message);
      setGridKey((prevKey) => prevKey + 1);
      saveAndClose();
    } catch (error) {
      console.error("Error updating Master data:", error);

      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message;
        setError(errorMessage);
        setTimeout(() => {
          setError("");
        }, 5000);
      } else {
       
        setMessage("");
        setError(error.errorMessage || error?.response?.data?.message);
        setTimeout(() => {
          setError("");
        }, 5000);
      }
    }
  };

  const [uploadedImage, setUploadedImage] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUploadedImage(file);
    setImagePath(null);
  };

  const resetAndClose = () => {
    setExternalName("");
    setPlatForm("");
    setMessage("");
    setError("");
    handleClose();
    setUploadedImage(null);
  };

  const saveAndClose = () => {
    setExternalName("");
    setPlatForm("");
    setImagePath("");
    setUploadedImage(null);
    handleClose();
  };

  useEffect(() => {
    if (message || error) {
      const timer = setTimeout(() => {
        setMessage("");
        setError("");
      }, 2000);

      return () => {
        clearTimeout(timer);
        setMessage("");
        setError("");
      };
    }
  }, [message, error]);

  return (
    <Dialog open={open}>
      <DialogContent>
        <form onSubmit={submitHandler}>
          <Typography variant="h6" gutterBottom>
            Update Master
          </Typography>
          <Grid  md={12} container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                fullWidth
              
                placeholder="External Name "
                type="text"
                name="externalName"
                value={masterData.externalName}
                onChange={handleInputChange}
                margin="normal"
                required
              />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  select
                  label="OS type"
                  variant="outlined"
                  name="platForm"
                  value={masterData.platForm}
                  onChange={handleInputChange}
                  fullWidth
                  required
                  sx={{ marginBottom: 1 }}
                  InputLabelProps={{ style: { marginTop: "4px" } }} 
                >
                  <MenuItem value="ALL">ALL</MenuItem>
                  <MenuItem value="ANDROID">ANDROID</MenuItem>
                  <MenuItem value="IOS">IOS</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />

                <label htmlFor="contained-button-file">
                  <Button variant="contained" component="span">
                    Upload Image
                  </Button>
                </label>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
            {(uploadedImage || imagePath) && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <img
                  src={imagePath || URL.createObjectURL(uploadedImage)}
                  // alt="Uploaded Banner"
                  style={{
                    maxWidth: "50%",
                    maxHeight: "100px", // Adjust the max height here
                    width: "auto",
                  }}
                />
                <IconButton
                  onClick={() => {
                    setUploadedImage(null);
                    setImagePath(null);
                  }}
                  sx={{ position: "absolute", top: 0, right: 0 }}
                >
                  <ClearIcon />
                </IconButton>
              </Box>
            )}
          </Grid>
          </Grid>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <span style={{ color: "green", fontWeight: "bold" }}>
              {message ? message : ""}
            </span>
            <span style={{ color: "red", fontWeight: "bold" }}>
              {error ? error : ""}
            </span>
          </div>
          &nbsp;
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={resetAndClose}
              style={{ background: "red", color: "white" }}
            >
              Close
            </Button>
            <Button
              type="submit"
              style={{
                background: "green",
                color: "white",
                marginLeft: "10px",
              }}
            >
              Save
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditMasterModal;
