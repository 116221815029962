


import React from 'react';

const StandardDate = (timestamp) => {
  var dt;
  if (timestamp !== null) {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    dt = day + "-" + month + "-" + year;
  } else {
    dt = "";
  }
  return dt;
};

export default StandardDate;


