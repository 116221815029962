import axiosInstance from "./interceptors/axiosInstance";
//function for iDynamic API
export async function fetchDynamicNav() {
  try {
    const response = await axiosInstance.get(`/api/admin/role/menu`);
    return response.data;
  } catch (error) {
    console.error("Error fetching Dynamic Nav list:", error);
    throw error;
  }
}
