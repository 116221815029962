import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import { cilLockLocked,cilUser } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { Link } from "react-router-dom";

import avatar8 from "../../../assets/profile.png";
import { logoutapi } from "../../../api/Login";

const AppHeaderDropdown = () => {
  const navigate = useNavigate();
  function logout() {
    logoutapi();
    localStorage.clear();
    navigate("/");
  }

  const linkStyles = {
    textDecoration: "none",
    color: "inherit",
    outline: "none",
    backgroundColor: "transparent",
    boxShadow: "none"
  };
  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar src={avatar8} size="md" />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
      <Link to="/admin-profile" style={linkStyles}>
        <CDropdownItem className="d-flex align-items-center">
          {/* <Link to="/admin-profile"  style={{textDecoration:"none" ,color:"grey"}} > */}
            <CIcon icon={cilUser} className="me-2" />
            Profile
          {/* </Link> */}
        </CDropdownItem>
        </Link>
        <CDropdownItem className="d-flex align-items-center" onClick={logout}>
          <CIcon icon={cilLockLocked} className="me-2" />
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
