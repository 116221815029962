import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../api/interceptors/axiosInstance";
import { ADD_TYPE_MASTER } from "../../../utils/constants/api_constant";

const AddModal = ({
  open,
  addHandleClose,
  setGridKey,
  slug,
}) => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [platForm, setPlatForm] = useState([]);
  const [externalName, setExternalName] = useState("");
  const [selectedfile, setSelectedFile] = useState(null);
  const [isSendMsgerror, setIsSendMsgerror] = useState("");

  const hiddenFileInput = useRef(null);
  const osOptions = ["ALL", "ANDROID", "IOS"];
  const handleFile = (file) => {

    setSelectedFile(file);
    setIsSendMsgerror("");
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = ""; // Clear the input value
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    const externalName = event?.target?.externalName.value;

    formData.append("externalName", externalName);
    formData.append("platForm", platForm);
    formData.append("parent", slug);

    if (uploadedImage) {
      formData.append("file", uploadedImage);
    }

    try {
      const response = await axiosInstance.post(`${ADD_TYPE_MASTER}`, formData);
      setMessage(response.data.message);
      setGridKey((prevKey) => prevKey + 1);
      saveAndClose();
    } catch (error) {
      console.error("Error updating Master data:", error);

      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message;
        setError(errorMessage);
        setTimeout(() => {
          setError("");
        }, 5000);
      } else {
        setMessage("");
        setError(error.errorMessage || error?.response?.data?.message);
        setTimeout(() => {
          setError("");
        }, 5000);
      }
    }
  };

  const resetAndClose = () => {
    setExternalName("");
    setPlatForm("");
    setSelectedFile("");
    setUploadedImage(null);
    setMessage("");
    setError("");
    addHandleClose();
  };
  const saveAndClose = () => {
    setExternalName("");
    setPlatForm("");
    setSelectedFile("");
    setUploadedImage(null);
    addHandleClose();
  };
  const [uploadedImage, setUploadedImage] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUploadedImage(file);
  };
  useEffect(() => {
    if (message || error) {
      const timer = setTimeout(() => {
        setMessage("");
        setError("");
      }, 2000);

      return () => {
        clearTimeout(timer);
        setMessage("");
        setError("");
      };
    }
  }, [message, error]);

  const handleClick = (e) => {
    e.preventDefault();
    hiddenFileInput.current.click();
  };
  const removeAttachment = (e) => {
    setSelectedFile("");
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = ""; // Clear the input value
    }
  };

  return (
    <Dialog open={open}>
      <DialogContent>
        <form onSubmit={submitHandler}>
          <Typography variant="h6" gutterBottom>
            Add Master Data
          </Typography>
          <TextField
            fullWidth
            sx={{ marginBottom: "20px" }}
           placeholder="External Name"
            variant="outlined"
            value={externalName}
            name="externalName"
            onChange={(e) => setExternalName(e.target.value)}
            margin="normal"
            required
          />
          <FormControl fullWidth sx={{ minWidth: 50, marginBottom: "20px" }}>
            <InputLabel sx={{ margin: "5px" }}>OS Type *</InputLabel>
            <Select
              name="platForm"
              value={platForm}
              onChange={(event) => setPlatForm(event.target.value)}
              required
            >
              {osOptions.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Grid item xs={6}>
            <input
              accept="image/*"
              id="contained-button-file"
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />

            <label htmlFor="contained-button-file">
              <Button variant="contained" component="span">
                Upload Image
              </Button>
            </label>
          </Grid>
          <Grid item xs={6} sx={{ mt: 2 }}>
            {uploadedImage && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <img
                  src={URL.createObjectURL(uploadedImage)}
                  // alt="Uploaded Banner"
                  style={{
                    maxWidth: "50%",
                    maxHeight: "100px", 
                    width: "auto",
                  }}
                />
                <IconButton
                  onClick={() => setUploadedImage(null)}
                  sx={{ position: "absolute", top: 0, right: 0 }}
                >
                  <ClearIcon />
                </IconButton>
              </Box>
            )}
          </Grid>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <span style={{ color: "green", fontWeight: "bold" }}>
              {message ? message : ""}
            </span>
            <span style={{ color: "red", fontWeight: "bold" }}>
              {error ? error : ""}
            </span>
          </div>
          &nbsp;
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
              onClick={resetAndClose}
              style={{ background: "red", color: "white" , marginRight: "10px", }}
            >
              Close
            </Button>
            <Button
              type="submit"
              style={{
                background: "green",
                color: "white",
               
              }}
            >
              Save
            </Button>
           
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddModal;
