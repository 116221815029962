export const LOGIN_ENDPOINT = "/api/auth/login";
export const OTP_VERIFY_ENDPOINT = "/api/auth/verify-otp";
export const LOGOUT_ENDPOINT = "/api/auth/logout";
export const CHAT_CUSTOMERS_LIST = `/admin/api/chat/get-chat-customers`;
export const CHAT_HISTORY = `/admin/api/chat/get-messages`;
export const CHAT_SEND_MESSAGE = `/admin/api/chat/send-message`;
export const COMMISSION_CONFIG_GET_ALL = `/admin/api/commission-configurations/get-all`;
export const COMMISSION_CONFIG_HISTORY = `/admin/api/commission-configurations/get-all`;
export const ADD_COMMISSION_CONFIG = `/admin/api/commission-configurations`;
export const INSTRUMENT_CONFIG_GET_ALL = `/api/admin/instrument-configurations/get-all`;
export const ADD_TYPE_MASTER = `/admin/api/type-masters`;
export const ADD_VERSION = `/admin/api/common/version`;
export const MASTER_DROPDOWN = `/api/admin/common/menu-categories/master-dropdown`;
export const UPDATE_WALLET = `/admin/api/wallet/update`;
export const ADD_MENU = `/admin/api/common/menu-categories`;
export const EDIT_MENU = `/admin/api/common/menu-categories`;
export const BULK_REFUND_LIST = `/api/admin/payment/bulkRefundIds`;
export const REFUND_TXN = `/api/admin/payment/transactionIdDetails`;
export const INITIATE_REFUND = `/api/admin/payment/bulkrefund`;
export const ADD_ADMIN = `admin/api/role/new-admin`;
export const API_MONITOR = `admin/api/api-monitor-page`;
export const GET_CUS_TICKETS = `/api/admin/chat/getAllTickets`;
export const EDIT_ADMIN = `/admin/api/role/edit-admin`;
export const EDIT_VERSION = `/admin/api/common/version`;
export const DISCOUNT_RESTRICTION = `/admin/api/discount/restriction/get-list-based-on-user`;
export const ADD_DISCOUNT_RESTRICTION = `/admin/api/discount/add/restriction`;
export const EDIT_DISCOUNT_RESTRICTION = `/admin/api/discount/update/restriction`;
export const CUSTOMERS_FEEDBACK = `/admin/api/chat/getAllCustomerFeedback`;
export const ADD_ALERT_NOTICE = `/admin/api/addAppNotice`;
export const UPDATE_ALERT_NOTICE = `/admin/api/appNoticeUpdate`;
// export const GET_MENU = `/admin/api/common/menu-categories`
// export const DELETE_COMMISSION_CONFIG= `/admin/api/commission-configurations`;
export const UPDATE_REFUND = "/api/admin/payment/refund";
 
 