import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  Grid,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Box,
  IconButton,
  FormHelperText,
} from "@mui/material";
import axiosInstance from "../../../api/interceptors/axiosInstance";
import { UPDATE_ALERT_NOTICE } from "../../../utils/constants/api_constant";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ClearIcon from "@mui/icons-material/Clear";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ImageIcon from "@mui/icons-material/Image"; // Import the Image icon
import dayjs from "dayjs";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const UpdateAlertNotice = ({
  open,
  handleClose,
  selectedRowData,
  handleSaveClick,
  setGridKey,
}) => {
  const [appNoticeMessage, setAppNoticeMessage] = useState("");
  const [error, setError] = useState("");
  const [title, setTitle] = useState("");
  const [routeType, setRouteType] = useState("");
  const [message, setMessage] = useState("");
  const [osName, setOsName] = useState("ALL");
  const [androidMinVersion, setAndroidMinVersion] = useState("");
  const [iosMinVersion, setIosMinVersion] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [description, setDescription] = useState("");
  const [selectedfile, setSelectedFile] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [imagePath, setImagePath] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [androidError, setAndroidError] = useState(false);
  const [iosError, setIosError] = useState(false);
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [appNoticeMessageError, setAppNoticeMesasgeError] = useState("");

 
  const osType = ["ALL", "ANDROID", "IOS"];
  // Alert data to update
  const [alertData, setAlertData] = useState({
    title: "",
    description: "",
    routeType: "",
    osName: "",
    androidMinVersion: "",
    iosMinVersion: "",
    image: "",
    startDate: "",
    endDate: "",
    message: "",
  });
  //Ferching Data
  useEffect(() => {
    if (selectedRowData && selectedRowData.row) {
      const startDateValue = selectedRowData.row.startDate
        ? selectedRowData.row.startDate
        : "";
      const endDateValue = selectedRowData.row.endDate
        ? selectedRowData.row.endDate
        : "";
      var [day, month, year] = startDateValue.split("-");
      const alertStartDate = dayjs(month + "-" + day + "-" + year).format(
        "DD-MMM-YYYY"
      );
      [day, month, year] = endDateValue.split("-");
      const alertEndDate = dayjs(month + "-" + day + "-" + year).format(
        "DD-MMM-YYYY"
      );
      setAlertData({
        id: selectedRowData.row.id || "",
        title: selectedRowData.row.title || "",
        description: selectedRowData.row.description || "",
        appNoticeMessage: selectedRowData.row.appNoticeMessage || "",
        routeType: selectedRowData.row.routeType || "",
        osName: selectedRowData.row.osName || "",
        image: selectedRowData.row.image || "",
        androidMinVersion: selectedRowData.row.androidMinVersion || "",
        iosMinVersion: selectedRowData.row.iosMinVersion || "",
        startDate: dayjs(alertStartDate) || "",
        endDate: dayjs(alertEndDate) || "",
      });
      setImagePath(selectedRowData.row.image || "");
    }
  }, [selectedRowData]);
  //Updating  data functionilty
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAlertData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  // File  changes Handling
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUploadedImage(file);
    setImagePath(null);
  };
  // SnackBar Changes
  const handleOpenSnackbar = (severity, message) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (message || error) {
      const timer = setTimeout(() => {
        setMessage("");
        setError("");
      }, 2000);

      return () => {
        clearTimeout(timer);
        setMessage("");
        setError("");
      };
    }
  }, [message, error]);
  //set the date format
  const handleStartDate = (date) => {
    if (date) {
      setAlertData((prevData) => ({
        ...prevData,
        startDate: date,
      }));
    }
  };
  //set the date format
  const handleEndDate = (date) => {
    if (date) {
      setAlertData((prevData) => ({
        ...prevData,
        endDate: date,
      }));
    }
  };
  //API call to edit Data
  const submitEditHandler = async (event) => {
    event.preventDefault();
    if (!alertData.startDate || !alertData.endDate) {
      setError("Please select both start and end dates.");
      return;
    }

    const formDataToSend = new FormData();
    const data = {
      id: alertData.id,
      title: alertData.title,
      routeType: alertData.routeType,
      appNoticeMessage: alertData.appNoticeMessage,
      description: alertData.description,
      androidMinVersion: alertData.androidMinVersion,
      iosMinVersion: alertData.iosMinVersion,
      osName: alertData.osName,
    };

    // Format dates if valid
    if (alertData.startDate && dayjs(alertData.startDate).isValid()) {
      data.startDate = dayjs(alertData.startDate).format("DD-MM-YYYY");
    }

    if (alertData.endDate && dayjs(alertData.endDate).isValid()) {
      data.endDate = dayjs(alertData.endDate).format("DD-MM-YYYY");
    }
    // Append the fields to formDataToSend
    for (const key in data) {
      formDataToSend.append(key, data[key]);
    }
    if (uploadedImage) {
      formDataToSend.append("image", uploadedImage);
    }

    try {
      const response = await axiosInstance.put(
        `${UPDATE_ALERT_NOTICE}`,
        formDataToSend
      );
      handleOpenSnackbar("success", response.data.message); // Show success message
      setTimeout(() => {
        setGridKey((prevKey) => prevKey + 1);
        handleClose();
      }, 2000);
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.message);
        setTimeout(() => {
          setError("");
        }, 5000);
      } else {
        setMessage("");
        setError(error.errorMessage || error?.response?.data?.message);
        setTimeout(() => {
          setError("");
        }, 5000);
      }
    }
  };
  // Validation function for Android version
  const validateVersion = (version) => {
    const regex = /^(?!0{1,}\.0{1,}\.0{1,}$)\d{1,2}\.\d{1,2}\.\d{1,2}$/;
    if (!regex.test(version)) {
      return false;
    }
    return true;
  };
  //Validating  andriod version
  const handleAndroidChange = (e) => {
    const value = e.target.value;
    setAndroidMinVersion(value);
    const versions = value.split(",").map((version) => version.trim());
    const invalidVersions = versions.filter(
      (version) => !validateVersion(version)
    );
    if (invalidVersions.length > 0) {
      setAndroidError(
        "Invalid format. Please use the format X.X.X, where a comma is used to separate each version."
      );
    } else {
      setAndroidError("");
    }
  };
  // Reset Input onClose
  const resetAndClose = () => {
    setTitleError("");
    setDescriptionError("");
    setAppNoticeMesasgeError("");
    handleClose();
  };
  //Validating  iOs Version
  const handleIosChange = (e) => {
    const value = e.target.value;
    setIosMinVersion(value);
    const versions = value.split(",").map((version) => version.trim());
    const invalidVersions = versions.filter(
      (version) => !validateVersion(version)
    );
    if (invalidVersions.length > 0) {
      setIosError(
        "Invalid format. Please use the format X.X.X, where a comma is used to separate each version."
      );
    } else {
      setIosError("");
    }
  };

  // screen design JSX
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog open={open} maxWidth="md" fullWidth>
        <DialogContent>
          <form onSubmit={submitEditHandler}>
            <Typography variant="h6" gutterBottom>
              Update Alert Notice
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Title"
                  variant="outlined"
                  name="title"
                  value={alertData.title}
                  onChange={(e) => {
                    let value = e.target.value;
                    const trimmedValue = value.trim();
                    if (trimmedValue.length >= 3 && trimmedValue.length <= 50) {
                      setAlertData({ ...alertData, title: value });
                      setTitleError(false);
                    } else {
                      setAlertData({ ...alertData, title: value });
                      setTitleError(true);
                    }
                    handleInputChange(e);
                  }}
                  required
                  InputLabelProps={{ style: { marginTop: "4px" } }}
                  error={titleError}
                  helperText={
                    titleError
                      ? "Title must be between 3 and 50 characters"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Route Type"
                  variant="outlined"
                  value={alertData.routeType}
                  onChange={handleInputChange}
                  name="routeType"
                  // margin="normal"
                  InputLabelProps={{ style: { marginTop: "4px" } }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "1px" }}>
              <Grid item xs={12} sm={4}>
                <FormControl
                  fullWidth
                  sx={{ minWidth: 50, marginBottom: "20px" }}
                >
                  <InputLabel sx={{ margin: "3px" }}>OS Type *</InputLabel>
                  <Select
                    name="osName"
                    required
                    value={alertData.osName}
                    onChange={(e) => {
                      handleInputChange(e);
                      setOsName(e.target.value);
                    }}
                  >
                    {osType.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Grid container spacing={2}>
                  {(alertData.osName === "ALL" ||
                    alertData.osName === "ANDROID") && (
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        sx={{ minWidth: 50, marginBottom: "20px" }}
                      >
                        <TextField
                          label="Android Version"
                          type="text"
                          name="androidMinVersion"
                          value={alertData.androidMinVersion}
                          onChange={(e) => {
                            handleInputChange(e);
                            handleAndroidChange(e);
                          }}
                          error={Boolean(androidError)}
                          helperText={androidError}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {(alertData.osName === "ALL" ||
                    alertData.osName === "IOS") && (
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        sx={{ minWidth: 50, marginBottom: "20px" }}
                      >
                        <TextField
                          label="iOS Version"
                          type="text"
                          name="iosMinVersion"
                          value={alertData.iosMinVersion}
                          onChange={(e) => {
                            handleInputChange(e);
                            handleIosChange(e);
                          }}
                          error={Boolean(iosError)}
                          helperText={iosError}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                {/* DatePicker component */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    renderInput={(props) => (
                      <TextField {...props} fullWidth label="Select Date" />
                    )}
                    value={alertData.startDate}
                    label={<div style={{ margin: "5px" }}>Start Date*</div>}
                    onChange={handleStartDate}
                    // disablePast
                    format="DD-MM-YYYY"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        required: true,
                        className: "hide-asterisk",
                      },
                    }}
                    fullWidth
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                {/* DatePicker component */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    renderInput={(props) => (
                      <TextField {...props} fullWidth label="Select Date" />
                    )}
                    value={alertData.endDate}
                    label={<div style={{ margin: "5px" }}>End Date*</div>}
                    onChange={handleEndDate}
                    // disablePast
                    format="DD-MM-YYYY"
                    fullWidth
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        required: true,
                        className: "hide-asterisk",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={3} xl={2}>
                <input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />

                <label htmlFor="contained-button-file">
                  <Button variant="contained" component="span">
                    Upload <ImageIcon /> 
                  </Button>
                </label>
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                {(uploadedImage || imagePath) && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <img
                      src={imagePath || URL.createObjectURL(uploadedImage)}
                      // alt="Uploaded Banner"
                      style={{
                        maxWidth: "50%",
                        maxHeight: "100px",
                        width: "auto",
                      }}
                    />
                    <IconButton
                      onClick={() => {
                        setUploadedImage(null);
                        setImagePath(null);
                      }}
                      sx={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={1}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  name="description"
                  label={<div style={{ margin: "5px" }}>Description*</div>}
                  value={alertData.description}
                  multiline
                  rows={3}
                  fullWidth
                  onChange={(e) => {
                    let value = e.target.value;
                    const trimmedValue = value.trim();

                    if (
                      trimmedValue.length >= 10 &&
                      trimmedValue.length <= 255
                    ) {
                      setDescription(value);
                      setDescriptionError(false);
                    } else {
                      setDescription(value);
                      setDescriptionError(true);
                    }

                    handleInputChange(e);
                  }}
                  error={descriptionError}
                  helperText={
                    descriptionError
                      ? "Description must be between 10 and 255 characters and cannot be empty or just spaces"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  name="appNoticeMessage"
                  label={<div style={{ margin: "5px" }}>Message*</div>}
                  value={alertData.appNoticeMessage}
                  multiline
                  rows={3}
                  fullWidth
                  onChange={(e) => {
                    let value = e.target.value;
                    const trimmedValue = value.trim();
                    if (
                      trimmedValue.length >= 10 &&
                      trimmedValue.length <= 255
                    ) {
                      setAppNoticeMessage(value);
                      setAppNoticeMesasgeError(false);
                    } else {
                      setAppNoticeMessage(value);
                      setAppNoticeMesasgeError(true);
                    }
                    handleInputChange(e);
                  }}
                  error={appNoticeMessageError}
                  helperText={
                    appNoticeMessageError
                      ? "App Notice Alert must be between 10 and 255 characters"
                      : ""
                  }
                />
              </Grid>
            </Grid>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <span style={{ color: "green", fontWeight: "bold" }}>
                {message ? message : ""}
              </span>
              <span style={{ color: "red", fontWeight: "bold" }}>
                {error ? error : ""}
              </span>
            </div>
            &nbsp;
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                style={{
                  background: "red",
                  color: "white",
                  marginRight: "10px",
                }}
                onClick={resetAndClose}
              >
                Close
              </Button>
              <Button
                type="submit"
                style={{ background: "green", color: "white" }}
                onClick={handleSaveClick}
              >
                Save
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={snackbarSeverity}
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </LocalizationProvider>
  );
};

export default UpdateAlertNotice;
