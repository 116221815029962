import axiosInstance from "../api/interceptors/axiosInstance";
export const fetchMasterData = async () => {
  try {
    const response = await axiosInstance.get(
      "/api/admin/type-masters/all-type-master"
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching API data:", error);
    throw error; // Rethrow the error to handle it in the component
  }
};
