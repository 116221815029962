// import { createStore} from 'redux';
import { configureStore } from '@reduxjs/toolkit'
import chatReducer from './slices/ChatSlice';
import HamburgerReducer from './slices/HamburgerSlice';
import PasswordReducer from './slices/PasswordSlice';


const initialState = {
  sidebarShow: true,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}
const reducer = {
  chatReducer,
  HamburgerReducer,
  PasswordReducer,
}

// const store = createStore(changeState)
const store = configureStore({
  reducer,
  
})
export default store
