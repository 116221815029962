import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import axiosInstance from "../../../api/interceptors/axiosInstance";
import CustomButton from "../CustomButton";
import Grid from "@material-ui/core/Grid";
import { fetchRoles } from "../../../api/FetchRoles";
import { Box, Modal } from "@mui/material";
import {EDIT_ADMIN} from '../../../utils/constants/api_constant';

const EditAdmin = ({
  open,
  handleClose,
  selectedRowData,
  setGridKey,
  assignedPermission,
}) => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  const [modules, setModules] = useState([]);
  // const [selectedReadModules, setSelectedReadModules] = useState([]);
  // const [selectedWriteModules, setSelectedWriteModules] = useState([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  // Validation state variables
  const [nameError, setNameError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [roleError, setRoleError] = useState("");
  const [disabledReadCheckboxes, setDisabledReadCheckboxes] = useState(false);

  const [adminData, setAdminData] = useState({
    userId: "",
    name: "",
    mobile: "",
    roleId: "",
    permissionsList: "",
    moduleName: "",
    accessType: "",
    moduleId: "",
  });
  //Css for layouts
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    submitButton: {
      marginTop: theme.spacing(2),
      display: "flex",
      justifyContent: "flex-end",
    },
  }));
  const classes = useStyles();

  const clearError = (fieldName) => {
    switch (fieldName) {
      case "name":
        setNameError("");
        break;
      case "mobile":
        setMobileError("");
        break;
      case "email":
        setEmailError("");
        break;
      case "role":
        setRoleError("");
        break;
      default:
        break;
    }
  };
//Get the value of user
  useEffect(() => {
    if (selectedRowData) {
      setAdminData({
        userId: selectedRowData.row.userId || "",
        name: selectedRowData.row.name || "",
        mobile: selectedRowData.row.mobile || "",
        email: selectedRowData.row.email || "",
        roleId: selectedRowData.row.roleId || "",
        moduleName: selectedRowData.row.permissionsList.moduleName || "",
        accessType: selectedRowData.row.permissionsList.accessType || "",
        moduleId: selectedRowData.row.permissionsList.moduleId || "",
      });
    }
  }, [selectedRowData]);

  useEffect(() => {
    // Check if assignedInstruments is not empty
    if (selectedRowData.row.permissionsList.length > 0) {
      const assignPermission = selectedRowData.row.permissionsList
        .filter((permission) => permission.accessType !== "N/A")
        .map(({ moduleId, accessType }) => ({
          moduleId,
          accessType,
        }));
      setSelectedCheckboxes(assignPermission);
    }
  }, [assignedPermission]);

  // Update name and mobile state directly
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAdminData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "name") {
      setName(value);
    } else if (name === "mobile") {
      setMobile(value);
    }
  };
  const validateMobile = (mobile) => {
    const mobileRegex = /^[0-9]{10}$/;
    return mobileRegex.test(mobile);
  };

  useEffect(() => {
    setSelectedRole(adminData.roleId);
  }, [adminData.roleId]);

//API to edit admin
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log(selectedRole);
    let isValid = true;

    // Validate name
    if (!adminData.name.trim()) {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }
  
    // Validate mobile
    if (!adminData.mobile) {
      setMobileError("Mobile is required");
      isValid = false;
    } else if (!validateMobile(adminData.mobile)) {
      setMobileError("Invalid mobile number");
      isValid = false;
    } else {
      setMobileError("");
    }
  
  
    // Validate role
    if (!selectedRole) {
      setRoleError("Role is required");
      isValid = false;
    } else {
      setRoleError("");
    }
  
    if (!isValid) {
      return;
    }

    

    try {
      const response = await axiosInstance.put(`${EDIT_ADMIN}`, {
        userId: selectedRowData.row.userId, 
        name: adminData.name,
        mobile: adminData.mobile,
        roleId: selectedRole,
        permissionsList: selectedCheckboxes,
      });
      setMessage(response.data.message);
      setTimeout(() => {
        setMessage("");
        handleClose();
      }, 500);
      setTimeout(() => {
        setGridKey((prevKey) => prevKey + 1);
      }, 500);
    } catch (error) {
      setMessage("");
      setError(error.errorMessage || error?.response?.data?.message);

      setTimeout(() => {
        setError("");
      }, 2000);
      console.error("Error submitting data:", error);
    }
  };
//Fetching Modules
  useEffect(() => {
    const fetchModuleData = () => {
      try {
        setModules(selectedRowData.row.permissionsList);
      } catch (error) {
        console.error("Error fetching modules:", error);
      }
    };

    fetchModuleData();
  }, []);
//FEtching Roles
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchRoles();
        setRoles(data);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchData();
  }, []);


//Component to module checkbox
  const renderModuleCheckboxes = () => {
    return (
      <div style={{ margin: "5px" }}>
        <Grid container spacing={2} style={{ margin: "-8px", padding: "10px" }}>
          {modules.map((module) => {
            const isAccessTypeReadOrWrite =
              module.accessType === "READ" || module.accessType === "WRITE";
            const accessType = module.accessType;
            const isWriteAccess = module.accessType === "WRITE";
            return (
              <Grid item xs={12} sm={6} md={3} key={module.moduleId}>
                <label style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    key={module.moduleId}
                    value={module.moduleId}
                    defaultChecked={isAccessTypeReadOrWrite}
                    onChange={(event) => {
                      const moduleId = event.target.value;
                      const isChecked = event.target.checked;

                      if (isChecked) {
                        setSelectedModules((prevModules) => {
                          if (prevModules.includes(moduleId)) {
                            return prevModules;
                          } else {
                            return [...prevModules, moduleId];
                          }
                        });
                      } else {
                        setSelectedModules((prevModules) =>
                          prevModules.filter((id) => id !== moduleId)
                        );
                        setSelectedCheckboxes((prevCheckboxes) =>
                          prevCheckboxes.filter(
                            (item) => item.moduleId !== moduleId
                          )
                        );
                      }
                    }}
                    style={{ marginRight: "12px" }}
                  />

                  <span>{module.moduleName}</span>
                </label>
                {(selectedModules.includes(module.moduleId) ||
                  module.accessType !== "N/A") && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    <label
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "20px",
                      }}
                    >
                      <input
                        type="checkbox"
                        defaultChecked={accessType === "READ" ? true : false}
                        disabled={isWriteAccess}
                        id={`read-checkbox-${module.moduleId}`}
                        onChange={(event) => {
                          const accessType = event.target.value;
                          const moduleId = module.moduleId;

                          if (event.target.checked) {
                            setSelectedCheckboxes((prevModules) => [
                              ...prevModules,
                              { moduleId: moduleId, accessType: accessType },
                            ]);
                            setDisabledReadCheckboxes(false); // Enable Read checkbox when Write is checked
                          } else {
                            setSelectedCheckboxes((prevModules) =>
                              prevModules.filter(
                                (item) =>
                                  !(
                                    item.moduleId === moduleId &&
                                    item.accessType === accessType
                                  )
                              )
                            );
                            setDisabledReadCheckboxes(true); // Disable Read checkbox when Write is unchecked
                          }
                        }}
                        value="READ"
                        style={{ marginRight: "8px" }}
                      />
                      <span>Read</span>
                    </label>
                    <label style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="checkbox"
                        defaultChecked={accessType === "WRITE" ? true : false}
                        onChange={(event) => {
                          const isChecked = event.target.checked;
                          const accessType = isChecked ? "WRITE" : "READ";
                          const moduleId = module.moduleId;
                          setSelectedCheckboxes((prevModules) => {
                            const filteredModules = prevModules.filter(
                              (item) =>
                                !(
                                  item.moduleId === moduleId &&
                                  item.accessType === "READ"
                                )
                            );
                            if (isChecked) {
                              // Disable Read checkbox when Write is checked
                              document.getElementById(
                                `read-checkbox-${moduleId}`
                              ).disabled = true;
                              return [
                                ...filteredModules,
                                { moduleId: moduleId, accessType: accessType },
                              ];
                            } else {
                              // Enable Read checkbox when Write is unchecked
                              document.getElementById(
                                `read-checkbox-${moduleId}`
                              ).disabled = false;
                              return filteredModules.filter(
                                (item) =>
                                  !(
                                    item.moduleId === moduleId &&
                                    (item.accessType === "WRITE" ||
                                      item.accessType === "READ")
                                  )
                              );
                            }
                          });
                        }}
                        value="WRITE"
                        style={{ marginRight: "8px" }}
                      />
                      <span>Write</span>
                    </label>
                  </div>
                )}
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  };

  
  // const handleRoleChange = (event) => {
  //   const roleId = event.target.value;
  //   setSelectedRole(roleId);
  // };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "80%",
          height: "auto",
          maxWidth: "60%",
          maxHeight: "100%",
          overflow: "auto",
          // boxSizing: "border-box",
          bgcolor: "background.paper",
          // boxShadow: 24,
          p: 3,
        }}
      >
        <h5> Update User Permission</h5>
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <span style={{ color: "green", fontWeight: "bold" }}>
            {message ? message : ""}
          </span>
          <span style={{ color: "red", fontWeight: "bold" }}>
            {error ? error : ""}
          </span>
        </div>
        &nbsp;
        <div>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Name"
                  InputLabelProps={{ style: { marginTop: "4px" } }}
                  id="outlined-size-small"
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                  value={adminData.name}
                  onChange={handleInputChange}
                  name="name"
                  error={!!nameError}
                  helperText={nameError}
                />

               
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="outlined-size-small"
                  InputLabelProps={{ style: { marginTop: "4px" } }}
                  size="small"
                  required
                  label="Mobile"
                  fullWidth
                  variant="outlined"
                  value={adminData.mobile}
                  onChange={handleInputChange}
                  name="mobile"
                  error={!!mobileError}
                  helperText={mobileError}
                />
                
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="outlined-size-small"
                  InputLabelProps={{ style: { marginTop: "4px" } }}
                  size="small"
                  label="Email"
                  required
                  fullWidth
                  variant="outlined"
                  value={adminData.email}
                  disabled
                  onChange={handleInputChange}
                />
                <span style={{ color: "red", fontWeight: "bold" }}>
                  {emailError}
                </span>
              </Grid>
              &nbsp;
              <Grid container>
                <span style={{ color: "red", fontWeight: "bold" }}>
                  {roleError}
                </span>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {/* <div style={{ display: "flex", alignItems: "center" }}> */}
                    <h6
                      style={{
                        textAlign: "left",
                        margin: "7px",
                        color: "#000",
                        width: "150px",
                      }}
                    >
                      <b>Select Role:</b>
                    </h6>

                    <Grid container  xs={12} sm={12} md={12} lg={12}>
                      {roles.map((role) => (
                        <Grid
                          item
                          key={role.roleId}
                          style={{ marginRight: "20px" }}
                        >
                          <label>
                            <input
                              type="radio"
                              required
                              value={role.roleId}
                              checked={selectedRole === role.roleId}
                              onChange={() => setSelectedRole(role.roleId)}
                              style={{ marginRight: "5px" }}
                            />
                            <span>{role.roleName.replace(/_/g, " ")}</span>{" "}
                          </label>
                        </Grid>
                      ))}
                    </Grid>
                  {/* </div> */}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h6
                    style={{
                      textAlign: "left",
                      color: "#000",
                      
                    }}
                  >
                    <b>Select Modules:</b>
                  </h6>
                </div>
                &nbsp;
                <Grid container spacing={2} >
                  {renderModuleCheckboxes()}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.submitButton}>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="error"
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <CustomButton
                    variant="contained"
                    color="primary"
                    size="small"
                    type="submit"
                    value="Submit"
                  />
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      </Box>
    </Modal>
  );
};
export default EditAdmin;
