import React from 'react';

const DateFormatter = ({ dateString }) => {
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true // Include this option to display time in 12-hour format
  };
  const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
  return <span>{formattedDate}</span>;
};


// const DateFormatter = ({ dateString }) => {
//   // Parse the input date string
//   const date = new Date(dateString);

//   // Extract date components
//   const day = String(date.getDate()).padStart(2, '0');
//   const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
//   const year = date.getFullYear();
//   const hour = String(date.getHours()).padStart(2, '0');
//   const minute = String(date.getMinutes()).padStart(2, '0');

//   // Format the time in 12-hour format
//   const meridiem = hour < 12 ? 'AM' : 'PM';
//   const hour12 = hour % 12 || 12; // Convert 0 to 12 for midnight
//   const formattedTime = `${hour12}:${minute} ${meridiem}`;

//   // Construct the formatted date string
//   const formattedDate = `${month}/${day}/${year}, ${formattedTime}`;

//   // Return the formatted date
//   return <span>{formattedDate}</span>;
// };


// const DateFormatter = ({ dateString }) => {
//   // Parse the input date string
//   const date = new Date(dateString);

//   // Extract date components
//   const day = String(date.getUTCDate()).padStart(2, '0');
//   const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
//   const year = date.getUTCFullYear();
//   const hour = String(date.getUTCHours()).padStart(2, '0');
//   const minute = String(date.getUTCMinutes()).padStart(2, '0');

//   // Format the time in 12-hour format
//   const meridiem = hour < 12 ? 'AM' : 'PM';
//   const hour12 = hour % 12 || 12; // Convert 0 to 12 for midnight
//   const formattedTime = `${hour12}:${minute} ${meridiem}`;

//   // Construct the formatted date string
//   const formattedDate = `${month}/${day}/${year}, ${formattedTime} `;

//   // Return the formatted date
//   return <span>{formattedDate}</span>;
// };


export default DateFormatter;
