// import React from 'react'
// import { AppContent, AppHeader } from "../components/navbar";

// const AccessDenied = () => {
//   return (
//     <div className="wrapper d-flex flex-column min-vh-100 bg-light">
//     <AppHeader />
//     <div className="body flex-grow-1 px-3">
      
//     <div className="offline" style={{marginLeft:"2S00px"}}>
//       <h3 style={{marginLeft:"14em" ,color:"red" ,marginTop:"20px"}}>Access  Denied</h3>
//       <p style={{marginLeft:"21em"}} >
//        Please Contact  Administrator for access.
//       </p>
//     </div>
//     </div>
//     </div>
//   )
// }

// export default AccessDenied

import React from 'react';
import { Grid } from '@mui/material';
import { AppContent, AppHeader } from '../components/navbar';

const AccessDenied = () => {
  return (
    <Grid container direction="column" className="wrapper min-vh-100 bg-light">
      <Grid item>
        <AppHeader />
      </Grid>
      <Grid item container justifyContent="center" className="body">
        <div className="offline">
          <h3 style={{ color: 'red', marginTop: '20px' }}>Access Denied</h3>
          <p  style={{marginLeft:"-4em"}}>Please contact the administrator for access.</p>
        </div>
      </Grid>
    </Grid>
  );
};

export default AccessDenied;


