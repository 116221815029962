import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isPasswordSet: false,
};

export const passwordSlice = createSlice({
  name: "isPasswordSet",
  initialState,
  reducers: {
    selectedIsPasswordSet: (state, action) => {
      state.isPasswordSet = action.payload;

    },
  },
});

export default passwordSlice.reducer;
export const {selectedIsPasswordSet,} = passwordSlice.actions;

