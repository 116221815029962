import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebarShow: true,
};

export const hamburgerSlice = createSlice({
  name: "toggleHamburgerSlice",
  initialState,
  reducers: {
    toggleHamburger: (state, action) => {
      state.sidebarShow = action.payload;
    },
  },
});
export default hamburgerSlice.reducer;
export const { toggleHamburger } = hamburgerSlice.actions;
