import React, { Suspense, useEffect, useState ,useContext } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
// import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import "./components/navbar/scss/style.scss";
import NotFound from "./pages/NotFound";
import Offline from "./pages/Offline";
import MasterData from "./pages/MasterData";
import { AppSidebar } from "./components/navbar";
import { fetchDynamicNav } from "./api/DynamicNav";
import AccessDenied from "./pages/AccessDenied";
import EditAdmin from "./components/common/UserPermission/EditAdmin";
import AdminProfile from "./pages/AdminProfile";
const Login = React.lazy(() => import("./pages/Login"));
const OtpVerify = React.lazy(() => import("./pages/OtpVerify"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const TransactionList = React.lazy(() => import("./pages/TransactionList"));
const CustomersList = React.lazy(() => import("./pages/CustomersList"));
const CustomerDetails = React.lazy(() => import("./pages/CustomerDetails"));
const CustomerDetailsRestriction = React.lazy(() =>
  import("./pages/CustomerDetailsRestriction")
);
const TransactionDetail = React.lazy(() => import("./pages/TransactionDetail"));
const Banners = React.lazy(() => import("./pages/Banners"));
const Discount = React.lazy(() => import("./pages/Discount"));
const Muilist = React.lazy(() => import("./pages/MuiTable"));
const BulkResult = React.lazy(() =>
  import("./components/common/BulkRefund/BulkResult")
);
const ApiMonitor = React.lazy(() => import("./pages/ApiMonitor"));
const CustomerTickets = React.lazy(() => import("./pages/CustomerTickets"));
const UserTypeMaster = React.lazy(() => import("./pages/UserTypeMaster"));
const PaymentbreakdownTypeMaster = React.lazy(() =>
  import("./pages/PaymentBreakdownTypeMaster")
);
const UserPermission = React.lazy(() => import("./pages/UserPermission"));
const AddAdmin = React.lazy(() =>
  import("./components/common/UserPermission/AddAdmin")
);
const CommissionConfig = React.lazy(() => import("./pages/CommissionConfig"));
const CommissionConfigHistory = React.lazy(() =>
  import("./pages/CommissionConfigHistory")
);

const BulkRefund = React.lazy(() => import("./pages/BulkRefund"));
const RefundDetails = React.lazy(() =>
  import("./components/common/BulkRefund/RefundDetails")
);
const InstrumentConfig = React.lazy(() => import("./pages/InstrumentConfig"));

const Version = React.lazy(() => import("./pages/Version"));
const Wallet = React.lazy(() => import("./pages/Wallet"));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
const Menu = React.lazy(() => import("./pages/Menu"));

const PrivateRoute = ({ element }) => {
  // Check if the access token exists in localStorage
  const accessToken = localStorage.getItem("adocket");
  // If the access token exists, render the provided element (e.g., Dashboard)
  // Otherwise, redirect to the login page
  return accessToken ? element : <Navigate to="/" />;
};



const CheckLogedinRoute = ({ element, routeData }) => {
  const accessToken = localStorage.getItem("adocket");
  const firstRoutePath = routeData.length > 0 ? routeData[0].path : "/";
  return accessToken ? <Navigate to={firstRoutePath} /> : element;
};

function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [lastVisitedRoute, setLastVisitedRoute] = useState("/");
  const location = useLocation();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [routeData, setRouteData] = useState([]);
  const [isPathMatched, setIsPathMatched] = useState(true);

  const customersListRoute = routeData.find(
    (route) => route.element === "CustomersList"
  );
  const bulkListRoute = routeData.find(
    (route) => route.element === "BulkRefund"
  );
  const customersListAccessType = customersListRoute
    ? customersListRoute.accessType
    : null;
  const bulkListAccessType = bulkListRoute ? bulkListRoute.accessType : null;

  useEffect(() => {
    // Check online status and update the state
    const onlineStatusHandler = () => {
      setIsOnline(navigator.onLine);
    };
    // addPasswordSet(true);

    window.addEventListener("online", onlineStatusHandler);
    window.addEventListener("offline", onlineStatusHandler);

    return () => {
      window.removeEventListener("online", onlineStatusHandler);
      window.removeEventListener("offline", onlineStatusHandler);
    };
  }, []);

  const [accessToken, setAccessToken] = useState(null);
  useEffect(() => {
    const token = localStorage.getItem("adocket");
    if (token) {
      setAccessToken(token);
    }
  }, [localStorage]);

  // Check if there's a last visited route in localStorage
  useEffect(() => {
    const storedRoute = localStorage.getItem("lastVisitedRoute");
    if (storedRoute) {
      setLastVisitedRoute(storedRoute);
    }
  }, []);

  // Save the current route in localStorage
  useEffect(() => {
    if (location.pathname !== "/") {
      localStorage.setItem("lastVisitedRoute", location.pathname);
    }
  }, [location]);

  useEffect(() => {
    if (isOnline) {
      const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
      if (lastVisitedRoute) {
        navigate(lastVisitedRoute); // Navigate to the last visited route when online
      }
    }
  }, [isOnline, navigate]);

  // const generateMenuItems = async () => {
  //   try {
  //     const fetchedData = await fetchDynamicNav();
  //     setRouteData(fetchedData);
  //   } catch (error) {
  //     console.error("Error fetching menu items:", error);
  //     return [];
  //   }
  // };

  const generateMenuItems = async () => {
    try {
      const fetchedData = await fetchDynamicNav();
      setRouteData(fetchedData);
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        navigate('/Access-denied');
      } else {
        console.error("Error fetching menu items:", error);
      }
    }
  };

  useEffect(() => {
    if (
      accessToken !== null &&
      localStorage.getItem("isPasswordSet") === "true"
    ) {
      const fetchDynamicMenu = async () => {
        try {
          await generateMenuItems();
        } catch (error) {
          console.error("Error fetching master data:", error);
        }
      };
      fetchDynamicMenu();
    }
  }, [accessToken]);

  useEffect(() => {
    // Check if the current path is matched with any route in routeData
    if (routeData && routeData.length > 0) {
      const matched = routeData.some(
        (route) => route.path === location.pathname
      );
      setIsPathMatched(matched);
    }
  }, [location.pathname, routeData]);

  const getFirstRoutePath = (routeData) => {
    if (routeData.length > 0) {
      return routeData[0].path;
     
    }
    // console.log(getFirstRoutePath);
    // return "/Access-denied"; // Fallback path if routeData is empty
  };

  // const CheckLogedinRoute = ({ element, routeData }) => {
  //   const accessToken = localStorage.getItem("x-auth-access-token");
  //   const firstRoutePath = getFirstRoutePath(routeData);
  //   console.log(firstRoutePath);

  //   return accessToken ? <Navigate to={firstRoutePath} /> : element;
  // };



  return (
     
    <Suspense fallback={loading}>
      {isOnline ? (
        <>
          {location.pathname !== "/" &&
            location.pathname !== "/otp_verification" &&
            location.pathname !== "*" && <AppSidebar />}

          <Routes>
            {/* <Route
              path="/"
              name="Login"
              element={<CheckLogedinRoute element={<Login />} />}
            />
            <Route
              path="/otp_verification"
              name="OtpVerify"
              element={<CheckLogedinRoute element={<OtpVerify />} />}
            /> */}
            <Route
              path="/"
              name="Login"
              element={
                <CheckLogedinRoute element={<Login />} routeData={routeData} />
              }
            />
            {/* <Route
              path="/otp_verification"
              name="OtpVerify"
              element={
                <CheckLogedinRoute
                  element={<OtpVerify />}
                  routeData={routeData}
                />
              }
              /> */}
            <Route
              path="/otp_verification"
              name="OtpVerify"
              element={
                <CheckLogedinRoute
                  element={<OtpVerify routeData={routeData} />}
                  routeData={routeData}
                />
              }
            />

            {routeData.map((route, index) => {
              const LazyComponent = React.lazy(() =>
                import(`./pages/${route.element}`)
              );

              return (
                <Route
                  key={index}
                  path={route.path + "/*"}
                  name={route.name}
                  element={
                    <PrivateRoute
                      element={<LazyComponent accessType={route.accessType} />}
                    />
                  }
                />
              );
            })}
            {routeData.some((route) => route.element === "TransactionList") && (
              <Route
                path="/transaction_detail/:trnid"
                name="Transaction Detail"
                element={<PrivateRoute element={<TransactionDetail />} />}
              />
            )}
            {routeData.some((route) => {
              return route.element === "CustomersList";
            }) && (
              <Route
                path="/customer-details/:userId"
                name="Customerdetails"
                element={
                  <PrivateRoute
                    element={
                      <CustomerDetails
                        customersListAccessType={customersListAccessType}
                      />
                    }
                  />
                }
              />
            )}
            {routeData.some((route) => {
              return route.element === "CustomersList";
            }) && (
              <Route
                path="/customer-details-restriction/:userId"
                name="CustomerdetailsRestriction"
                element={
                  <PrivateRoute element={<CustomerDetailsRestriction />} />
                }
              />
            )}

            {routeData.some((route) => route.element === "BulkRefund") && (
              <Route
                path="/refund_detail/:refundid"
                name="Refund Detail"
                element={
                  <PrivateRoute
                    element={
                      <RefundDetails bulkListAccessType={bulkListAccessType} />
                    }
                  />
                }
              />
            )}
            {routeData.some((route) => route.element === "BulkRefund") && (
              <Route
                path="/bulk-result/*"
                name="BulkResult"
                element={
                  <PrivateRoute
                    element={
                      <BulkResult bulkListAccessType={bulkListAccessType} />
                    }
                  />
                }
              />
            )}

            {routeData.some(
              (route) => route.element === "CommissionConfig"
            ) && (
              <Route
                path="/commission-history"
                name="CommissionConfigHistory"
                element={<PrivateRoute element={<CommissionConfigHistory />} />}
              />
            )}

            <Route path="/Access-denied" name="NotFound" element={<NotFound />} /> 

            {/* {localStorage.getItem("x-auth-access-token") !== null && !isPathMatched ? (
              <Route path="*" element={<AccessDenied />} />
            ) : null} */}
            {/* {!routeData.some(
                (route) => route.element === location.pathname
            ) && (
                <Route path="/*" element={<AccessDenied />} />
            )} */}

            <Route
              path="/add-admin"
              name="AddAdmin"
              element={<PrivateRoute element={<AddAdmin />} />}
            />
            <Route
              path="/edit-admin/:userId"
              name="EditAdmin"
              element={<PrivateRoute element={<EditAdmin />} />}
            />

            <Route
              path="/admin-profile"
              name="AdminProfile"
              element={<PrivateRoute element={<AdminProfile />} />}
            />
             <Route
              path="/transaction"
              name="TransactionList"
              element={<PrivateRoute element={<AdminProfile />} />}
            />
            {/* {localStorage.getItem("x-auth-access-token") !== null && !isPathMatched ? (
              <Route path="*" element={<AccessDenied />} />
            ) : null}

            {localStorage.getItem("isPasswordSet") !== "true" ? (
              <Route
                path="*"
                element={
                  <>
                    
                    <PrivateRoute element={<AdminProfile routeMessage="Access Denied: Please reset password!"/>} />
                  </>
                }
              />
              <Route
              path="/admin-profile"
              name="AdminProfile"
              element={<PrivateRoute element={<AdminProfile />} />}
            /> */}
            
            {localStorage.getItem("adocket") !== null && !isPathMatched ? (
              <Route path="*" element={<AccessDenied />} />
            ) : null}
            {localStorage.getItem("isPasswordSet") !== "true" ? (
              <Route
                path="*"
                element={
                  <AdminProfile routeMessage="Access Denied: Please reset password!" />
                }
              />
            ) : (
              <Route
                path="/master-data/:slug/*"
                name="MasterData"
                element={<PrivateRoute element={<MasterData />} />}
              />
            )}
          </Routes>
        
        </>
      ) : (
        // Show the OfflinePage when offline
        <Offline />
      )}
    </Suspense>
  
  );
}

function MainApp() {
  return (
    <BrowserRouter>
   
      <App />
   
    </BrowserRouter>
  );
}
export default MainApp;
