
// import axios from "axios";
// import { refreshAccessToken } from "./authService";
// import configDefault from "../../utils/config/configManager";

// const instance = axios.create({

 
//   baseURL: configDefault,
//   headers: {
//     'Content-Type': 'application/json; charset=UTF-8',
//   },
// });

// // instance.interceptors.request.use(
// //   async (config) => {
// //     const accessToken = localStorage.getItem("adocket"); // Replace with your token key
// //     if (accessToken) {
// //       config.headers.Authorization = `Bearer ${accessToken}`;
// //     }
// //     return config;
// //   },
// //   (error) => {
// //     if (error.response) {
// //       const statusCode = error.response.status;
      
// //       // Handle 400 Bad Request and 404 Not Found errors
// //       if (statusCode === 400 || statusCode === 404) {
// //         const errorMessage = error?.response?.data?.message;
// //         error.errorMessage = errorMessage; // Attach the error message to the error object
// //       }

// //       // Handle 401 Unauthorized and 403 Forbidden
// //       if (statusCode === 401 || statusCode === 403) {
// //         // Clear token and redirect to login
// //         localStorage.clear();
// //         window.location.href = "/";
// //       }
// //     }

// //     return Promise.reject(error);
// //   }
// // );


// instance.interceptors.request.use(
//   async (config) => {
//     const accessToken = localStorage.getItem("adocket"); 
//     if (accessToken) {
//       config.headers.Authorization = `Bearer ${accessToken}`;
//     }
//     return config;
//   },
//   (error) => Promise.reject(error) 
// );

// instance.interceptors.response.use(
//   (response) => response, 
//   (error) => {
//     if (error.response) {
//       const statusCode = error.response.status;
      
//       if (statusCode === 400 || statusCode === 404) {
//         const errorMessage = error?.response?.data?.message;
//         error.errorMessage = errorMessage;
//       }

    
//       if (statusCode === 401 || statusCode === 403) {
        
//         localStorage.clear();
//         window.location.href = "/"; 
//       }
//     }

//     return Promise.reject(error);
//   }
// );



// export default instance;



import axios from "axios";
import configDefault from "../../utils/config/configManager";

const instance = axios.create({
  baseURL: configDefault,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
  },
});

instance.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem("adocket");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const statusCode = error.response.status;
      const originalRequest = error.config;
      
      // If status is 401 and it's not the root route, reject with error
      if (statusCode === 401 && originalRequest.url !== '/') {
        const errorMessage = error?.response?.data?.message || 'Unauthorized';
        localStorage.clear();
        window.location.href = "/"; 
        return Promise.reject({ ...error, errorMessage });
      }
      
      // Handle other error statuses
      if (statusCode === 400 || statusCode === 404) {
        const errorMessage = error?.response?.data?.message;
        error.errorMessage = errorMessage;
      }

      if ((statusCode === 401 || statusCode === 403) && originalRequest.url !== '/') {
        localStorage.clear();
        window.location.href = "/"; 
      }
    }

    return Promise.reject(error);
  }
);

export default instance;



