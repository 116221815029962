import React from "react";
import { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { table_unique_key } from "../../../src/utils/constants/table_unique_id";

import "./css/Grid.css";

const CustomNoRowsOverlay = () => {
  return (
    <div style={{ padding: '30px', textAlign: 'center' }}>
     No data found.
    </div>
  );
};

const Grid = ({ columns, fetchData, gridName }) => {
  const { useState } = React;
  // const uid = gridName === 'Transactions' ? 'transactionId': 'userId';
  const uid = table_unique_key[gridName];
  const [pageState, setPageState] = useState({
    isLoading: true,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  useEffect(() => {
    fetchData(pageState, setPageState);
  }, [pageState.page, pageState.pageSize]);
  const gridColumns = columns.map((obj) => ({ ...obj, sortable: false }));
  return (
    <div className="App">
      <DataGrid
        getRowId={(row) => row[uid]}
        autoHeight
        rows={pageState.data}
        rowCount={pageState.total}
        loading={pageState.isLoading}
        // rowsPerPageOptions={[5]}
        pagination
        page={pageState.page - 1}
        pageSize={pageState.pageSize}
        // onPageSizeChange={handlePageSizeChange}
        rowsPerPageOptions={[10, 20, 50]}
        paginationMode="server"
        onPageChange={(newPage) => {
          setPageState((old) => ({ ...old, page: newPage + 1 }));
        }}
        onPageSizeChange={(newPageSize) => {
          setPageState((old) => ({ ...old, pageSize: newPageSize }));
        }}
        columns={gridColumns}
        disableColumnMenu
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
      />
    </div>
  );
};

export default Grid;
