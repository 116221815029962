import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CSidebar, CSidebarNav } from "@coreui/react";
import { AppSidebarNav } from "./AppSidebarNav";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { Link } from "react-router-dom";
// import { fetchDynamicNav } from "../../api/DynamicNav";
import { fetchDynamicNav } from "../../api/DynamicNav";
import navigation from "./_nav"; // sidebar nav config
import "../common/css/Nav.css";
import Innopay from "./Innopay.png";
import { fetchMasterData } from "../../api/MasterDataAPI";
import CIcon from "@coreui/icons-react";
import { CNavItem, CNavGroup } from "@coreui/react";
import { cilChevronRight } from "@coreui/icons";
import {
  cilSpeedometer,
  cilGroup,
  cilList,
  cilLibrary,
  cilApplications,
  cilStorage,
  cilUnderline,
  cilSortNumericDown,
  cilWallet,
  cilCog,
  cilTask,
  cilChatBubble,
  cilTransfer,
  cilUserPlus,
  cilMonitor,
  cilCash,
  cilTags,
  cilListRich,
  cilVoiceOverRecord,
  cilWarning,
} from "@coreui/icons";

const AppSidebar = () => {
  const dispatch = useDispatch();
  const [menuItemsList, setMenuItemsList] = useState(null);
  const unfoldable = useSelector((state) => state.sidebarUnfoldable);
  const sidebarShow = useSelector(
    (state) => state.HamburgerReducer.sidebarShow
  );
  let dynamicNavItems = null;
  let updatedNavigation = null;
  const generateMasterDataMenuItems = async () => {
    try {
      const fetchedData = await fetchMasterData();
      dynamicNavItems = fetchedData.map((item) => ({
        component: CNavItem,
        key: item.slug,
        name:
          item.externalName.charAt(0).toUpperCase() +
          item.externalName.slice(1),
        to: `/master-data/${item.slug}`,
        icon: (
          <CIcon
            icon={cilChevronRight}
            customClassName="nav-icon"
            style={{ color: "#fff", paddingLeft: "15px", fontSize: "5px" }}
          />
        ),
        style: { color: "#fff" },
        badge: {
          color: "warning",
        },
      }));
    } catch (error) {
      // Handle error as needed
      // console.error("Error fetching master data:", error);
      return [];
    }
  };

  const generateMenuItems = async () => {
    try {
      const fetchedData = await fetchDynamicNav();

      const menuItems = fetchedData.map((item) => {
        if (item.name !== "Master Data") {
          return {
            component: CNavItem,
            name: item.name,
            to: item.path,
            icon: (
              <CIcon
                icon={getIconForName(item.icon)}
                customClassName="nav-icon"
                style={{ color: "#fff", paddingLeft: "10px" }}
              />
            ),
            style: { color: "#fff" },
            badge: {
              color: "warning",
            },
          };
        } else {
          return {
            component: CNavGroup,
            name: item.name,
            to: item.path,
            icon: (
              <CIcon
                icon={getIconForName(item.icon)}
                customClassName="nav-icon"
                style={{ color: "#fff", paddingLeft: "10px" }}
              />
            ),
            style: { color: "#fff" },
            badge: {
              color: "warning",
            },
            disabled: false,

            items: dynamicNavItems,
          };
        }
      });

      updatedNavigation = navigation.map((navItem) =>
        navItem.name !== "Master Data"
          ? {
              ...navItem,
              items: menuItems,
            }
          : navItem
      );
      setMenuItemsList(updatedNavigation);
    } catch (error) {
      console.error("Error fetching menu items:", error);
      return [];
    }
  };
  useEffect(() => {
    const fetchDynamicMenu = async () => {
      try {
        await generateMasterDataMenuItems();
        await generateMenuItems();
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };
    fetchDynamicMenu();
  }, []);

  // Function to map icon names to corresponding icons from @coreui/icons
  const getIconForName = (name) => {
    switch (name) {
      case "cilChevronRight":
        return cilChevronRight;
      case "cilSpeedometer":
        return cilSpeedometer;
      case "cilStorage":
        return cilStorage;
      case "cilUnderline":
        return cilUnderline;
      case "cilSortNumericDown":
        return cilSortNumericDown;
      case "cilWallet":
        return cilWallet;
      case "cilTask":
        return cilTask;
      case "cilCog":
        return cilCog;
      case "cilChatBubble":
        return cilChatBubble;
      case "cilTransfer":
        return cilTransfer;
      case "cilGroup":
        return cilGroup;
      case "cilList":
        return cilList;
      case "cilLibrary":
        return cilLibrary;
      case "cilUserPlus":
        return cilUserPlus;
      case "cilMonitor":
        return cilMonitor;

      case "cilCash":
        return cilCash;
      case "cilTags":
        return cilTags;

      case "cilVoiceOverRecord":
        return cilVoiceOverRecord;

      case "cilListRich":
        return cilListRich;
      case "cilWarning":
        return cilWarning;

      default:
        return cilApplications; // Default icon if no match found
    }
  };

  return (
    <CSidebar
      className="custom-simplebar"
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: "set", sidebarShow: visible });
      }}
    >
      {/* <CSidebarBrand className="d-none d-md-flex" to="/">
       
        <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />
      </CSidebarBrand> */}

      {/* <Link
        to="/dashboard"
        style={{ textDecoration: "none", color: "inherit" }}
      > */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={Innopay}
            alt="Image"
            style={{ width: "9rem", margin: "1rem 3.5rem 0.5rem 3.5rem" }}
          />
          {/* <span style={{ fontSize: "30px" }}>Innopay</span> */}
        </div>
      {/* </Link> */}

      <CSidebarNav>
        {/* <SimpleBar className="custom-simplebar">
          {localStorage.getItem("isPasswordSet") === "true" ? (
            <AppSidebarNav items={menuItemsList} />
          ) : (
            ""
          )}
          {/* <AppSidebarNav items={menuItemsList} /> */}
          {/* { accessToken ?  <AppSidebarNav items={navigation} /> : ""} */}
        {/* </SimpleBar>  */}

        <SimpleBar className="custom-simplebar">
  {localStorage.getItem("isPasswordSet") === "true" ? (
    <AppSidebarNav items={menuItemsList || []} />
  ) : (
    ""
  )}
</SimpleBar>

      </CSidebarNav>
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
