import React from "react";
import { Button } from "@mui/material";
import { PropTypes } from "prop-types";
 
const CustomButton = (props) => {
  const {
    type = "button",
    variant = "contained",
    color,
    size = "medium",
    fullWidth = false,
    value,
    startIcon = "",
    endIcon = "",
    isDisabled = false,
    onClick,
    height,
    width,
    margin,
    isActive,
  } = props;
 
  // Style object to set height and width
  const buttonStyle = {
    height: height,
    width: width,
    margin: margin,
  };
 
  // Style object to set background color
  const buttonColor = isActive
    ? { backgroundColor: "red", color: "#FFFFFF" }
    : { backgroundColor: "#3F1E81", color: "#FFFFFF" };
 
  return (
    <Button
      type={type}
      variant={variant}
      color={color}
      size={size}
      fullWidth={fullWidth}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={isDisabled}
      onClick={onClick}
      style={{ ...buttonStyle, ...buttonColor }}
    >
      {value}
    </Button>
  );
};
 
CustomButton.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  startIcon: PropTypes.object,
  endIcon: PropTypes.object,
  onClick: PropTypes.func,
  height: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  isActive: PropTypes.bool,
};
 
export default CustomButton;