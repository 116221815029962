import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";

const ExpandableImage = ({ thumbnailSrc, fullSizeSrc, alt }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleImageClick = () => {
    setIsExpanded(!isExpanded);
  };
  // Check if the close icon was clicked before closing the modal
  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    setIsExpanded(false);
  };
  return (
    <div>
      <img
        src={thumbnailSrc}
        alt={alt}
        style={{ maxWidth: "80px", maxHeight: "60px", cursor: "pointer" }}
        onClick={handleImageClick}
      />
      <Dialog open={isExpanded} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>
          {alt}
          <IconButton
            edge="end"
            color="inherit"
            onClick={(event) => handleClose(event, "iconClick")}
            aria-label="close"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              marginRight: "2px",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <img
            src={fullSizeSrc}
            alt={alt}
            style={{ width: "100%", height: "50%" }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ExpandableImage;
